import React, { useState } from 'react';
import { Box, Text, UnorderedList, ListItem, Link, IconButton } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';
import UpdateFacilityInfo from './UpdateFacilityInfo';
import { Tooltip } from 'antd';
import { EditIcon } from '@chakra-ui/icons';

const FacilityInfo = ({ data, addressData, estId, updateList }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const address = [
    addressData?.line1,
    addressData?.line2,
    addressData?.city,
    addressData?.state,
    addressData?.country,
    addressData?.post_code,
  ]
    .filter(i => i !== null || i !== undefined || i !== '')
    .join(' ');
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURI(`${address}`)}`;

  return (
    <Box marginBottom="15px">
      <Box display="flex" justifyContent="space-between">
        <Text color="rgba(0, 0, 0, 0.87)" fontSize="14px" fontWeight="600" lineHeight="1.43" marginBottom="15px">
          Facility Information
        </Text>
        <Tooltip title="Update Facility Information">
          <IconButton variant="solid" colorScheme="actionSecondary" onClick={() => setIsOpenPopup(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <UnorderedList fontSize="14px" marginBottom="10px" color="#000000B2" style={{ columnCount: 2 }}>
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Address:
          </Text>
          {address ? (
            <Link
              href={mapUrl}
              marginLeft="4px"
              color="#3A44B0"
              textDecoration="underline"
              _hover={{
                color: '#3A44B0',
                textDecoration: 'underline',
              }}
              target="_blank"
            >
              {address}
            </Link>
          ) : (
            '-'
          )}
        </ListItem>
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Daily Production:
          </Text>
          <Text as="span" marginLeft="4px" fontWeight={400}>
            {data?.daily_production || '-'}
          </Text>
        </ListItem>
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Daily Boning:
          </Text>
          <Text as="span" marginLeft="4px" fontWeight={400}>
            {data?.daily_boining || '-'}
          </Text>
        </ListItem>
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Boning Process:
          </Text>
          <Text as="span" marginLeft="4px" fontWeight={400}>
            {data?.boning_process?.length > 0 ? data?.boning_process.join(', ') : '-'}
          </Text>
        </ListItem>
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Stunning Method:
          </Text>
          <Text as="span" marginLeft="4px" fontWeight={400}>
            {data?.stunning_method || '-'}
          </Text>
        </ListItem>
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Production Days:
          </Text>
          <Text as="span" marginLeft="4px" fontWeight={400}>
            {data?.production_days?.length > 0 ? data?.production_days?.join(', ') : '-'}
          </Text>
        </ListItem>
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Category:
          </Text>
          <Text as="span" marginLeft="4px" fontWeight={400}>
            {data?.category?.length > 0 ? data?.category?.join(', ') : '-'}
          </Text>
        </ListItem>        
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Shift Times:
          </Text>
          <Text as="span" marginLeft="4px" fontWeight={400}>
            {data?.shift_times?.length > 0 ? data?.shift_times.join(', ') : '-'}
          </Text>
        </ListItem>
        <ListItem marginBottom="10px" paddingRight="10px">
          <Text as="span" fontWeight={500}>
            Staff:
          </Text>
          <Text as="span" marginLeft="4px" fontWeight={400}>
            {data?.staff || ''}
          </Text>
          {data?.org_chart && (
            <Link
              href={data?.org_chart}
              marginLeft="4px"
              color="#3A44B0"
              textDecoration="underline"
              _hover={{
                color: '#3A44B0',
                textDecoration: 'underline',
              }}
            >
              (Org Chart)
            </Link>
          )}
        </ListItem>
      </UnorderedList>
      {isOpenPopup && (
        <UpdateFacilityInfo
          isOpenPopup={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
          editItem={data}
          estId={estId}
          updateList={updateList}
          addressData={addressData}
        />
      )}
    </Box>
  );
};

FacilityInfo.propTypes = {
  data: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    daily_production: PropTypes.string,
    daily_boining: PropTypes.string,
    boning_process: PropTypes.arrayOf(PropTypes.string),
    stunning_method: PropTypes.string,
    production_days: PropTypes.arrayOf(PropTypes.string),
    shift_times: PropTypes.arrayOf(PropTypes.string),
    staff: PropTypes.string,
    org_chart: PropTypes.string,
  }),
  addressData: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    post_code: PropTypes.string,
  }),
  estId: PropTypes.number,
  updateList: PropTypes.func,
};

export default FacilityInfo;
