import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';

const DeleteDocumentModal = ({ show, editItem, handleClose, handleDelete, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const deleteHandler = () => {
        setIsLoading(true);
        handleDelete(editItem.id)
        setIsLoading(false);
    }
    return (
        <Modal isOpen={show} onClose={handleClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent maxW="min(400px, 80%)">
                <ModalHeader closeButton>
                    Delete Document
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Are you sure you want to delete this document? This action cannot be undone.</Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={handleClose} isLoading={isLoading}>
                        Cancel
                    </Button>
                    <Button colorScheme="red" onClick={deleteHandler} isLoading={isLoading}>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteDocumentModal;