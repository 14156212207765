import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Alert,
  AlertDescription,
  AlertIcon,
  Input,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import { get, isString, cloneDeep, isEqual } from 'lodash';
// Local Deps
import Constants from '../../../../Constants';
import SelectField from '../../../basic/SelectField';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../assets/trash-outline-icon.svg';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';

const PRODUCTION_DETAILS_LIMIT = 32;

const AddButton = ({ onClick, isDisabled, ...rest }) => (
  <IconButton
    onClick={onClick}
    isDisabled={isDisabled}
    icon={
      <i
        className="la la-plus international-order-line__icon--orange"
        style={{
          fontSize: '16px',
        }}
      />
    }
    colorScheme="actionSecondary"
    height="25px"
    minWidth="25px"
    borderRadius="32px"
    {...rest}
  />
);

AddButton.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

const DeleteButton = ({ onClick, isDisabled, ...rest }) => (
  <IconButton variant="ghost" icon={<TrashOutlineIconSVG />} isDisabled={isDisabled} onClick={onClick} {...rest} />
);

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

const TableTd = ({ children, ...rest }) => {
  return (
    <Td borderBottomWidth="0px" padding="6px 11px" {...rest}>
      {children}
    </Td>
  );
};

TableTd.propTypes = {
  children: PropTypes.node.isRequired,
};

function ProductionDetails({
  unitsOfMeasure,
  line,
  isInEditMode,
  onChange,
  isChicago,
  isFrozenProduct,
  buyUnitOfMeasureId,
  isUSADomestic,
  onUpdateLine,
  isInCreatePOMode
}) {
  const defaultQuantityUnitId = Constants.UNIT_OF_MEASURE.find(uom => uom.value === 'CARTON').id;
  // ----------------------
  // States
  // ----------------------
  const [expandedDetails, setExpandedDetails] = useState(isInCreatePOMode === true);
  const [productLines, setProductLines] = useState(cloneDeep(line));
  const [deletedProductionDetailIds, setDeletedProductionDetailIds] = useState([]);
  const [deletedEstimatedProductionDetailIds, setDeletedEstimatedProductionDetailIds] = useState([]);
  const [UOMOptions, setUOMOptions] = useState(() => {
    const uomOptions = unitsOfMeasure.map(el => {
      return { value: el.id, label: el.name.toUpperCase() };
    });
    return uomOptions;
  });
  const [dateValidationErrors, setDateValidationErrors] = useState({
    errorMsg: {
      text: null,
    },
  });

  const getformattedDate = reactDateInput => {
    // eslint-disable-next-line
    if (reactDateInput._isAMomentObject) {
      return reactDateInput.format('YYYY-MM-DD');
    }
    if (isString(reactDateInput)) {
      const reqDatePattern = /^(?:(19|20)[0-9]{2}[-.](0[1-9]|1[012])[-.](0[1-9]|[12][0-9]|3[01]))$/;
      return reqDatePattern.test(reactDateInput) ? reactDateInput : null;
    }
    return null;
  };

  // ---------------------------------
  // Functions
  // ---------------------------------
  const getColumns = () => {
    var cols = [
      {
        title: 'No',
        dataIndex: 'index',
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        isRequired: true,
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        isRequired: true,
      },
      {
        title: 'Production Date',
        dataIndex: 'productionDate',
        isRequired: true,
      }
    ]
    if (isUSADomestic && isFrozenProduct){

      cols =[
        {
          title: 'No',
          dataIndex: 'index',
        },
        {
          title: 'UOM',
          dataIndex: 'uom',
          isRequired: true,
        },
        {
          title: 'Weight',
          dataIndex: 'weight',
          isRequired: true,
        },
        {
          title: 'Production Date',
          dataIndex: 'productionDate',
          isRequired: true,
        },
        {
          title: 'Packer Lot Number',
          dataIndex: 'packerLotNumber',
          isRequired: false,
        },
      ]
    }
    return cols;
  }

  const onLineProductionDetailsChange = (idx, field, value, productionDetailKey) => {
    setProductLines(prevProdLines => {
      const productLineClone = cloneDeep(prevProdLines);
      const lineProductionDetail = productLineClone[productionDetailKey][idx];
      lineProductionDetail[field] = value;
      lineProductionDetail.purchaseOrderLineId = productLineClone.id;
      if (field === 'unitOfMeasureId' && lineProductionDetail.id) {
        lineProductionDetail.unitOfMeasure = unitsOfMeasure.find(uom => uom.id === value);
      }

      return productLineClone;
    });
  };

  const handleOrderIncrement = (count, productionDetailKey) => {    
    const productLineClone = cloneDeep(productLines);
    // increment
    if (count > productLineClone[productionDetailKey].length) {
      // length count starts from 0
      if (productLineClone[productionDetailKey].length < PRODUCTION_DETAILS_LIMIT) {
        productLineClone[productionDetailKey].push({
          ...Constants.PRODUCTION_DETAILS,
          ...(isChicago && !isFrozenProduct && { unitOfMeasureId: buyUnitOfMeasureId }),
        });
      }
    }
    // decrement
    else {
      productLineClone[productionDetailKey].pop();
    }
    setProductLines(productLineClone);
  };

  const onRemoveProductionDetailLine = (lineProductionDetailId, idx, productionDetailKey) => {
    const productLineClone = cloneDeep(productLines);

    if (lineProductionDetailId) {
      productLineClone[productionDetailKey].splice(
        productLineClone[productionDetailKey].findIndex(i => i.id === lineProductionDetailId),
        1
      );
      // save the removed ids and make a request to api on Save
      if (productionDetailKey === 'productionDetails') {
        setDeletedProductionDetailIds(productionDetailsIdsToDelete => [
          ...productionDetailsIdsToDelete,
          lineProductionDetailId,
        ]);
      } else if (productionDetailKey === 'estimatedProductionDetails') {
        setDeletedEstimatedProductionDetailIds(productionDetailsIdsToDelete => [
          ...productionDetailsIdsToDelete,
          lineProductionDetailId,
        ]);
      }
    } else {
      productLineClone[productionDetailKey].splice(idx, 1);
    }
    setProductLines(productLineClone);
  };

  const columns = getColumns();

  // -------------------------
  //   Lifecycle Hook
  // -------------------------
  useEffect(() => {
    let hasError = false;
    // Check if the page is in "Create PO" mode and if no production details are already added
    // console.log('productLinesss', productLines);
    
    if (isInCreatePOMode && !productLines.estimatedProductionDetails.length) {      
      // Add a default production line when the page is loaded and it's "Create PO" mode  
      handleOrderIncrement(productLines.estimatedProductionDetails.length + 1, 'estimatedProductionDetails');
    }
    productLines.productionDetails.forEach((lineProdDetail, idx) => {
      const copy = { ...lineProdDetail };
      // Estimated Date is not available to edited in the UI, and
      // can be null.
      delete copy.estimatedDate;
      delete copy.packerLotNumber;

      if (Object.values(copy).some(i => i === '' || i === undefined || i === null)) {
        const sno = idx + 1;
        const errorMessage = `Missing/Incorrect values on row ${sno}`;
        setDateValidationErrors({
          errorMsg: {
            text: errorMessage,
          },
        });
        hasError = true;
      }
    });
    if (!hasError) {
      setDateValidationErrors({
        errorMsg: {
          text: null,
        },
      });

      onChange(
        productLines.idx,
        productLines.id,
        productLines.productionDetails,
        deletedProductionDetailIds,
        'productionDetails'
      );

      if (isUSADomestic && !isFrozenProduct)
        onChange(
          productLines.idx,
          productLines.id,
          productLines.estimatedProductionDetails,
          deletedEstimatedProductionDetailIds,
          'estimatedProductionDetails'
        );
    }

    return () => {
      setDeletedProductionDetailIds([]);
      setDeletedEstimatedProductionDetailIds([]);
      setDateValidationErrors(null);
    };
  }, [isInCreatePOMode,productLines]);

  const estimationOptions = [
    { value: 'confirmed', label: 'Confirmed' },
    { value: 'confirmed_critical', label: 'Confirmed Critical' },
    { value: 'not_confirmed', label: 'Not confirmed' },
  ];
  const renderTable = (productionDetailType, data) => {
    const productionDetailKey = productionDetailType === 'actual' ? 'productionDetails' : 'estimatedProductionDetails';

    return (
      <Table marginTop="15px">
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th key={column.dataIndex} paddingLeft={index === 0 && '6px'} padding="6px 11px">
                {column.title} {column.isRequired && isInEditMode ? ' *' : ''}
              </Th>
            ))}
            <Th key="actions" padding="6px 11px" />
          </Tr>
        </Thead>
        <Tbody>
          <>
            {data.map((lineProductionDetails, idx) => (
              <Tr key={idx}>
                <TableTd>{idx + 1}</TableTd>
                <TableTd>
                  <Box width="80px">
                    <SelectField
                      name={`select-uom_${idx}`}
                      placeholder="UOM"
                      value={UOMOptions.filter(
                        UOMOption =>
                          UOMOption.value ===
                          (lineProductionDetails.unitOfMeasureId
                            ? lineProductionDetails.unitOfMeasureId
                            : defaultQuantityUnitId)
                      )}
                      options={UOMOptions}
                      isDisabled={!isInEditMode}
                      onChange={e => {
                        onLineProductionDetailsChange(
                          idx,
                          'unitOfMeasureId',
                          e ? e.value : defaultQuantityUnitId,
                          productionDetailKey
                        );
                      }}
                    />
                  </Box>
                </TableTd>
                <TableTd>
                  <Input
                    name={isChicago ? 'weight' : 'shipped-quantity'}
                    className="form-control"
                    type="number"
                    value={lineProductionDetails.shippedQuantity || ''}
                    disabled={!isInEditMode}
                    bg={!isInEditMode ? 'inherit' : 'white'}
                    placeholder={isChicago ? 'Weight' : 'Shipped Quantity'}
                    onChange={e =>
                      onLineProductionDetailsChange(idx, 'shippedQuantity', e.target.value, productionDetailKey)
                    }
                    style={{ display: 'inline-block' }}
                  />
                </TableTd>
                <TableTd>
                  <DateTimeWithTrappedFocus
                    id={isChicago ? 'actual-cut-date' : 'production-date'}
                    inputProps={{
                      placeholder: isChicago ? 'Est. cut date' : 'Production Date',
                      disabled: !isInEditMode,
                    }}
                    input
                    closeOnSelect
                    value={lineProductionDetails.productionDate}
                    onChange={e =>
                      onLineProductionDetailsChange(idx, 'productionDate', getformattedDate(e), productionDetailKey)
                    }
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                  />
                </TableTd>
                {isUSADomestic && isFrozenProduct ? (
                <TableTd>
                  <Input
                    name={'packerLotNumber'}
                    className="form-control"
                    type="text"
                    value={lineProductionDetails.packerLotNumber || ''}
                    disabled={!isInEditMode}
                    bg={!isInEditMode ? 'inherit' : 'white'}
                    placeholder={'Packer Lot Number'}
                    onChange={e =>
                      onLineProductionDetailsChange(idx, 'packerLotNumber', e.target.value, productionDetailKey)
                    }
                    style={{ display: 'inline-block' }}
                  />
                </TableTd>
                ) : ''}
                <TableTd>
                  <ButtonGroup gap="4" alignItems="center">
                    <DeleteButton
                      isDisabled={!isInEditMode}
                      onClick={() => onRemoveProductionDetailLine(lineProductionDetails.id, idx, productionDetailKey)}
                    />
                    <AddButton
                      onClick={() => {
                        handleOrderIncrement(productLines[productionDetailKey].length + 1, productionDetailKey);
                      }}
                      isDisabled={!isInEditMode}
                    />
                  </ButtonGroup>
                </TableTd>
                
              </Tr>
            ))}
          </>
        </Tbody>
      </Table>
    );
  };

  return (
    <Box>
      <Flex my={3} justifyContent="start" alignItems="center">
        <HStack color="accent.one.default">
          <Text color="inherit" fontWeight="bold">
            Production Details
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="24px"
            icon={expandedDetails ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setExpandedDetails(!expandedDetails)}
          />
        </HStack>
        {!isUSADomestic || isFrozenProduct ? (
          <Button
            disabled={!isInEditMode}
            onClick={() => {
              setExpandedDetails(true);
              handleOrderIncrement(productLines.productionDetails.length + 1, 'productionDetails');
            }}
            marginLeft="auto"
          >
            <AddIcon />
          </Button>
        ) : (
          ''
        )}
      </Flex>

      {get(dateValidationErrors, 'errorMsg.text', null) && (
        <Alert status="error" marginBottom="10px">
          <AlertIcon />
          <AlertDescription>{dateValidationErrors.errorMsg.text}</AlertDescription>
        </Alert>
      )}

      {expandedDetails && (
        <Box width="562px">
          <Box paddingBottom={isUSADomestic && !isFrozenProduct && '30px'}>
            {isUSADomestic && !isFrozenProduct ? (
              <HStack spacing="24px" height="40px">
                <Text fontWeight="700">Estimated</Text>
                <Box width="170px">
                  <SelectField
                    name="select-estimated"
                    value={
                      estimationOptions.find(option => option.value === line.estimated) || {
                        value: 'confirmed_critical',
                        label: 'Confirmed Critical',
                      }
                    }
                    options={estimationOptions}
                    onChange={e => {
                      onUpdateLine('estimated', line.idx, get(e, 'value', e));
                    }}
                    isDisabled={!isInEditMode}
                  />
                </Box>
                {productLines.estimatedProductionDetails && productLines.estimatedProductionDetails.length < 1 ? (
                <AddButton
                  onClick={() => {
                    handleOrderIncrement(
                      productLines.estimatedProductionDetails.length + 1,
                      'estimatedProductionDetails'
                    );
                  }}
                  isDisabled={!isInEditMode}
                  marginLeft="auto !important"
                />

                  
                ) : (
                  ''
                )}
              </HStack>
            ) : (
              ''
            )}

            {productLines.estimatedProductionDetails &&
              productLines.estimatedProductionDetails.length > 0 &&
              renderTable('estimated', productLines.estimatedProductionDetails)}
          </Box>
          <Box
            {...(isUSADomestic &&
              !isFrozenProduct && {
                paddingTop: '19px',
                borderTopWidth: '1px',
              })}
          >
            {isUSADomestic &&  !isInCreatePOMode && !isFrozenProduct ? (
              <Flex height="40px" alignItems="center">
                <Text fontWeight="700">Actual</Text>
                {productLines.productionDetails.length < 1 ? (
                  <AddButton
                    onClick={() => {
                      handleOrderIncrement(productLines.productionDetails.length + 1, 'productionDetails');
                    }}
                    isDisabled={!isInEditMode}
                    marginLeft="auto !important"
                  />
                ) : (
                  ''
                )}
              </Flex>
            ) : (
              ''
            )}
            {productLines.productionDetails.length > 0 && renderTable('actual', productLines.productionDetails)}
          </Box>
        </Box>
      )}
    </Box>
  );
}

ProductionDetails.propTypes = {
  onChange: PropTypes.func,
  onUpdateLine: PropTypes.func,
  unitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.number,
      conversionRate: PropTypes.number,
    })
  ),
  isInEditMode: PropTypes.bool,
  isChicago: PropTypes.bool,
  isFrozenProduct: PropTypes.bool,
  line: PropTypes.instanceOf(PropTypes.object),
  buyUnitOfMeasureId: PropTypes.instanceOf(Object),
  isUSADomestic: PropTypes.bool,
};

const areEqual = (prevProps, nextProps) => {
  const keys = ['index', 'isInEditMode', 'line', 'unitsOfMeasure'];
  for (const key of keys) {
    if (!isEqual(prevProps[key], nextProps[key])) {
      return false;
    }
  }
  return true;
};

export default React.memo(ProductionDetails, areEqual);
