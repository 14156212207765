import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

import './VoyageDetailsItem.scss';
import SelectField from '../../../basic/SelectField';
import PortOptions from '../PortOptions';
import { Box, FormLabel, HStack, IconButton, Text, VStack, Wrap, Input } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import { checkIfETDEditable } from '../../../../utils';
import Tooltip from '../../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  editMode: PropTypes.bool,
  voyage: PropTypes.shape({
    loadPortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dischargePortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    transhipPortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shippingLineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vesselName: PropTypes.string,
    voyageNumber: PropTypes.string,
    eta: PropTypes.string,
    etd: PropTypes.string,
    ata: PropTypes.string,
    atd: PropTypes.string,
    voyageLegId: PropTypes.number,
  }),
  ports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  shippingLineOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  validationErrors: PropTypes.shape({
    dateError: PropTypes.bool,
    atDateError: PropTypes.bool,
  }),
  onVoyageValueChange: PropTypes.func,
  onExpansionChange: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
};

const VoyageDetailsItem = ({
  voyage,
  onVoyageValueChange,
  shippingLineOptions,
  editMode,
  ports,
  validationErrors,
  onExpansionChange,
  documents,
}) => {
  const [state, setState] = useState({
    voyageIsExpanded: true,
    portOfLoadingIsExpanded: true,
    portOfDischargeIsExpanded: true,
  });

  const isDisabled = !editMode;

  const etaProps = {
    className: 'form-control m-input',
    disabled: isDisabled,
  };
  const ataProps = {
    className: 'form-control m-input',
    disabled: isDisabled,
  };
  const ETDEditable = checkIfETDEditable(documents);
  const etdProps = {
    className: 'form-control m-input',
    disabled: isDisabled || !ETDEditable,
  };
  const atdProps = {
    className: 'form-control m-input',
    disabled: isDisabled,
  };

  const { dateError } = validationErrors;
  const { atDateError } = validationErrors;
  // TODO, removed default '' from input fields after fixing backend
  // Backend cannot accept empty string currently as marshamallow fails to deserialise

  useEffect(() => {
    (() => {
      onExpansionChange(state.voyageIsExpanded && state.portOfLoadingIsExpanded && state.portOfDischargeIsExpanded);
    })();
  }, [state]);

  return (
    <VStack spacing="21px" align="stretch">
      <VStack spacing="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="150px">
          <Text as="p" fontWeight="medium" color="inherit">
            Voyages
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="18px"
            icon={state.voyageIsExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setState({ ...state, voyageIsExpanded: !state.voyageIsExpanded })}
          />
        </HStack>
        {state.voyageIsExpanded && (
          <Wrap spacing="56px" width="100%">
            <CustomFormControl width="305px">
              <FormLabel htmlFor="select-vessel-name">
                Vessel Name&nbsp;
                <Tooltip content="Provides the name of the vessel transporting the goods." placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <Input
                backgroundColor="white"
                name="vesselName"
                value={voyage?.vesselName || ''}
                isDisabled={isDisabled}
                className="form-control"
                onChange={e => onVoyageValueChange(voyage, (e && e.target.value) || null, 'vesselName')}
              />
            </CustomFormControl>
            <CustomFormControl width="305px">
              <FormLabel htmlFor="select-voyage-number">
                Voyage Number&nbsp;
                <Tooltip content="Provides the voyage number assigned to the shipment." placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <Input
                backgroundColor="white"
                name="voyageNumber"
                value={voyage?.voyageNumber || ''}
                isDisabled={isDisabled}
                className="form-control"
                onChange={e => onVoyageValueChange(voyage, (e && e.target.value) || null, 'voyageNumber')}
              />
            </CustomFormControl>

            <CustomFormControl width="305px">
              <FormLabel htmlFor="select-shipping-line">
                Shipping Line&nbsp;
                <Tooltip content="Select the shipping line from the dropdown list." placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <SelectField
                isClearable
                isDisabled={isDisabled}
                name="shippingLine"
                defaultValue={voyage.shippingLineId}
                options={shippingLineOptions}
                onChange={e => onVoyageValueChange(voyage, (e && e.value) || null, 'shippingLineId')}
              />
            </CustomFormControl>
          </Wrap>
        )}
      </VStack>
      <VStack spacing="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="150px">
          <Text as="p" fontWeight="medium" color="inherit">
            Port of Loading
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="18px"
            icon={state.portOfLoadingIsExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setState({ ...state, portOfLoadingIsExpanded: !state.portOfLoadingIsExpanded })}
          />
        </HStack>
        {state.portOfLoadingIsExpanded && (
          <Wrap spacing="56px" width="100%">
            <CustomFormControl width="305px">
              <FormLabel htmlFor="port-of-loading">
                Port&nbsp;
                <Tooltip content="Identifies the port where the goods will be loaded." placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <PortOptions
                isDisabled={isDisabled}
                fieldType="LOAD"
                voyage={voyage}
                ports={ports}
                onVoyageValueChange={onVoyageValueChange}
              />
            </CustomFormControl>
            <CustomFormControl width="305px">
              <FormLabel htmlFor="eta">
                ETD&nbsp;
                <Tooltip
                  content="Provides the Estimated Time of Departure (ETD) for the port of loading."
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <DateTimeWithTrappedFocus
                id="etd"
                inputProps={etdProps}
                closeOnSelect
                value={voyage.etd}
                onChange={e => onVoyageValueChange(voyage, e._isAMomentObject ? e.format('YYYY-MM-DD') : null, 'etd')} // eslint-disable-line no-underscore-dangle
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
            <CustomFormControl width="305px">
              <FormLabel htmlFor="atd">
                ATD&nbsp;
                <Tooltip
                  content="Provides the Actual Time of Departure (ATD) for the port of loading."
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <DateTimeWithTrappedFocus
                id="atd"
                inputProps={atdProps}
                closeOnSelect
                value={voyage.atd}
                onChange={e => onVoyageValueChange(voyage, e._isAMomentObject ? e.format('YYYY-MM-DD') : null, 'atd')} // eslint-disable-line no-underscore-dangle
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
          </Wrap>
        )}
      </VStack>
      <VStack spacing="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="150px">
          <Text as="p" fontWeight="medium" color="inherit">
            Port of Discharge
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="18px"
            icon={state.portOfDischargeIsExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setState({ ...state, portOfDischargeIsExpanded: !state.portOfDischargeIsExpanded })}
          />
        </HStack>
        {state.portOfDischargeIsExpanded && (
          <Wrap spacing="56px" width="100%">
            <CustomFormControl width="305px">
              <FormLabel htmlFor="portOfDischarge">
                Port&nbsp;
                <Tooltip content="Specifies the destination port where the goods will be unloaded." placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <PortOptions
                isDisabled={isDisabled}
                fieldType="DISCHARGE"
                voyage={voyage}
                ports={ports}
                onVoyageValueChange={onVoyageValueChange}
              />
            </CustomFormControl>
            <CustomFormControl width="305px">
              <FormLabel htmlFor="eta">
                ETA&nbsp;
                <Tooltip
                  content="Provides the Estimated Time of Arrival (ETA) at the destination port."
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <DateTimeWithTrappedFocus
                id="eta"
                inputProps={etaProps}
                closeOnSelect
                value={voyage.eta}
                onChange={e => onVoyageValueChange(voyage, e._isAMomentObject ? e.format('YYYY-MM-DD') : null, 'eta')} // eslint-disable-line no-underscore-dangle
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
            {(dateError || atDateError) && (
              <CustomFormControl width="305px">
                <FormLabel htmlFor={atDateError ? "atDateError" : "dateError"} />
                <Alert 
                  message={
                    atDateError 
                      ? "Arrival(ATA) cannot occur before departure(ATD)"
                      : "Arrival(ETA) cannot occur before departure(ETD)"
                  } 
                  type="error" 
                />
              </CustomFormControl>
            )}
            <CustomFormControl width="305px">
              <FormLabel htmlFor="ata">
                ATA&nbsp;
                <Tooltip
                  content="Provides the Actual Time of Arrival (ATA) at the destination port."
                  placement="right"
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <DateTimeWithTrappedFocus
                id="ata"
                inputProps={ataProps}
                closeOnSelect
                value={voyage.ata}
                onChange={e => onVoyageValueChange(voyage, e._isAMomentObject ? e.format('YYYY-MM-DD') : null, 'ata')} // eslint-disable-line no-underscore-dangle
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
          </Wrap>
        )}
      </VStack>
      {state.portOfDischargeIsExpanded && (
        <Box>
          <Wrap spacing="56px" width="100%">
            <CustomFormControl width="305px">
              <FormLabel htmlFor="tranship-port">
                Tranship Port&nbsp;
                <Tooltip content="Select the transhipment port from the dropdown list." placement="right">
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    icon={<IoInformationCircleOutline size="14px" />}
                    variant="unstyled"
                  />
                </Tooltip>
              </FormLabel>
              <PortOptions
                isDisabled={isDisabled}
                fieldType="TRANSHIP"
                voyage={voyage}
                ports={ports}
                onVoyageValueChange={onVoyageValueChange}
              />
            </CustomFormControl>
          </Wrap>
        </Box>
      )}
    </VStack>
  );
};

VoyageDetailsItem.propTypes = propTypes;

export default VoyageDetailsItem;
