import Constants from '../Constants';
import api from '../api';

const basePath = '/op-docs-resources';

const actionTypes = {
  RESOURCES_REQUESTED: 'RESOURCES_REQUESTED',
  RESOURCES_REQUEST_SUCCESS: 'RESOURCES_REQUEST_SUCCESS',
  RESOURCES_REQUEST_FAILURE: 'RESOURCES_REQUEST_FAILURE',
  RESOURCES_ADD_NEW: 'RESOURCES_ADD_NEW',
  RESOURCES_UPDATE: 'RESOURCES_UPDATE',
  RESOURCES_DELETE: 'RESOURCES_DELETE',
  FETCH_PACKERS: 'FETCH_PACKERS',
  PACKERS_FETCH_SUCCESS: 'PACKERS_FETCH_SUCCESS',
  PACKERS_FETCH_ERROR: 'PACKERS_FETCH_ERROR',
  FETCH_CONTACTS: 'FETCH_CONTACTS',
  FETCH_CONTACTS_SUCCESS: 'FETCH_CONTACTS_SUCCESS',
  FETCH_CONTACTS_ERROR: 'FETCH_CONTACTS_ERROR',
  ADD_NEW_CONTACT: 'ADD_NEW_CONTACT',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',
};

const defaultState = {
  loading: true,
  error: null,
  editing: false,
  contacts: [],
};

export const actions = {
  requestResources() {
    return {
      type: actionTypes.RESOURCES_REQUESTED,
    };
  },
  resourcesRequestSuccess(resource) {
    return {
      type: actionTypes.RESOURCES_REQUEST_SUCCESS,
      payload: resource,
    };
  },
  resourcesRequestError(error) {
    return {
      type: actionTypes.RESOURCES_REQUEST_FAILURE,
      payload: error,
    };
  },
  addNewResource(resource) {
    return {
      type: actionTypes.RESOURCES_ADD_NEW,
      payload: resource,
    };
  },
  updateResource(resource) {
    return {
      type: actionTypes.RESOURCES_UPDATE,
      payload: resource,
    };
  },
  deleteResourceSuccess(id,type) {
    return {
      type: actionTypes.RESOURCES_DELETE,
      payload: {id,type},
    };
  },  
  fetchPackers() {
    return {
      type: actionTypes.FETCH_PACKERS,
    };
  },
  packersFetchSuccess(resource) {
    return {
      type: actionTypes.PACKERS_FETCH_SUCCESS,
      payload: resource,
    };
  },
  packersFetchError(error) {
    return {
      type: actionTypes.PACKERS_FETCH_ERROR,
      payload: error,
    };
  },
  fetchContacts() {
    return {
      type: actionTypes.FETCH_CONTACTS,
    };
  },
  fetchContactsSuccess(resource) {
    return {
      type: actionTypes.FETCH_CONTACTS_SUCCESS,
      payload: resource,
    };
  },
  fetchContactsError(error) {
    return {
      type: actionTypes.FETCH_CONTACTS_ERROR,
      payload: error,
    };
  },
  addNewContact(contact) {
    return {
      type: actionTypes.ADD_NEW_CONTACT,
      payload: contact,
    };
  },
  updateContact(contact) {
    return {
      type: actionTypes.UPDATE_CONTACT,
      payload: contact,
    };
  },
  deleteContact(id) {
    return {
      type: actionTypes.DELETE_CONTACT,
      payload: id,
    };
  },
};

export const fetchResources = (slug, paginationNFilters) => async dispatch => {
  dispatch(actions.requestResources());
  try {
    const response = await api.get(
      `${basePath}?type=${slug}&page=${paginationNFilters.currentPage}&limit=${paginationNFilters.itemsPerPage}${
        paginationNFilters.query ? `&search=${paginationNFilters.query}` : ''
      }${paginationNFilters.packerId ? `&rs_associated_id=${paginationNFilters.packerId}` : ''}${
        paginationNFilters.status ? `&status=${paginationNFilters.status}` : ''
      }${paginationNFilters.sortby ? `&sortby=${paginationNFilters.sortby}` : ''}`
    );
    const json = await Constants.handleErrors(response, dispatch, actions.resourcesRequestError);
    return dispatch(actions.resourcesRequestSuccess({ ...json, type: slug }));
  } catch (error_1) {
    return dispatch(actions.resourcesRequestError(error_1));
  }
};

export const fetchPackers = () => async dispatch => {
  dispatch(actions.requestResources());
  try {
    const response = await api.get('/packers');
    const json = await Constants.handleErrors(response, dispatch, actions.resourcesRequestError);
    return dispatch(actions.packersFetchSuccess(json));
  } catch (error) {
    return dispatch(actions.packersFetchError(error));
  }
};

export const fetchContacts = paginationNFilters => async dispatch => {
  dispatch(actions.fetchContacts());
  try {
    const response = await api.get(
      `/op-importantcontacts?limit=${paginationNFilters.itemsPerPage}&page=${paginationNFilters.currentPage}${
        paginationNFilters.query ? `&search=${paginationNFilters.query}` : ''
      }${paginationNFilters.contact_of ? `&contact_of=${paginationNFilters.contact_of}` : ''}${
        paginationNFilters.status ? `&status=${paginationNFilters.status}` : ''
      }${paginationNFilters.sortby ? `&sortby=${paginationNFilters.sortby}` : ''}`
    );
    const json = await Constants.handleErrors(response, dispatch, actions.fetchContactsError);
    return dispatch(actions.fetchContactsSuccess(json));
  } catch (error) {
    return dispatch(actions.fetchContactsError(error));
  }
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.RESOURCES_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        editing: false,
      };
    case actionTypes.RESOURCES_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.payload.type]: action.payload,
      };
    case actionTypes.RESOURCES_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.RESOURCES_ADD_NEW: {
      let prevItems = state[action.payload.type]?.items || [];
      if (
        action.payload.type === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle &&
        state[action.payload.type]?.items &&
        state[action.payload.type]?.items.length > 0 &&
        action.payload?.status?.toLowerCase() === 'active'
      ) {
        prevItems = state[action.payload.type]?.items.map(itm => {
          if (itm.status.toLowerCase() === 'active') {
            return { ...itm, status: 'Idle' };
          }

          return itm;
        });
      }

      return {
        ...state,
        loading: false,
        [action.payload.type]: {
          ...state[action.payload.type],
          items: [...prevItems, action.payload],
          total: state[action.payload.type]?.total + 1,
          pages:
            state[action.payload.type]?.items.length >= (state[action.payload.type]?.limit || 10)
              ? state[action.payload.type]?.pages + 1
              : 1,
        },
      };
    }
    case actionTypes.RESOURCES_UPDATE:
      return {
        ...state,
        loading: false,
        [action.payload.type]: {
          ...state[action.payload.type],
          items: state[action.payload.type]?.items.map(itm => {
            if (
              itm.id !== action.payload.id &&
              action.payload.type === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle &&
              itm.status.toLowerCase() === 'active'
            ) {
              return { ...itm, status: 'Idle' };
            }
            if (itm.id === action.payload.id) {
              return action.payload;
            }

            return itm;
          }),
        },
      };
    case actionTypes.RESOURCES_DELETE:
      return {
        ...state,
        loading: false,
        [action.payload.type]: {
          ...state[action.payload.type],
          items: state[action.payload.type]?.items.filter(itm => itm.id !== action.payload.id),
          total: state[action.payload.type]?.total - 1,
          pages:
            state[action.payload.type]?.items.length > (state[action.payload.type]?.limit || 10)
              ? state[action.payload.type]?.pages - 1
              : 1,
        },
      };
    case actionTypes.FETCH_PACKERS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PACKERS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        packers: action.payload,
      };
    case actionTypes.PACKERS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_CONTACTS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.payload,
      };
    case actionTypes.FETCH_CONTACTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_NEW_CONTACT:
      return {
        ...state,
        loading: false,
        contacts: {
          ...state.contacts,
          items: [...(state.contacts.items || []), action.payload],
        },
      };
    case actionTypes.UPDATE_CONTACT:
      return {
        ...state,
        loading: false,
        contacts: {
          ...state.contacts,
          items: state.contacts.items.map(itm => (itm.id === action.payload.id ? action.payload : itm)),
        },
      };
    case actionTypes.DELETE_CONTACT:
      return {
        ...state,
        loading: false,
        contacts: {
          ...state.contacts,
          items: state.contacts.items.filter(itm => itm.id !== action.payload),
        },
      };
    case actionTypes.BLOCK_CONTACT:
      return {
        ...state,
        loading: false,
        contacts: {
          ...state.contacts,
          items: state.contacts.items.map(itm => (itm.id === action.payload ? { ...itm, status: 'Deactivated' } : itm)),
        },
      };
    default:
      return state;
  }
};

export default reducer;
