import { invoke, isArray, isEqual, isObject, transform } from 'lodash';
import moment from 'moment';
import React from 'react';
import { SelectColumn } from 'react-data-grid';
import DataGrid from '../../components/basic/ResultsGrid';
import { useSelector } from 'react-redux';
import { HEADER_ROW_HEIGHT, HEIGHT_BUFFER, ROW_HEIGHT } from '../../components/basic/ResultsGrid/ResultsGrid';
import { DatePickerEditor, SelectEditor, InputEditor } from '../../components/gridHelpers';
import OrderActionsFormatter from '../../components/gridHelpers/OrderActionsFormatter';
import { findOptionMatch } from '../../components/gridHelpers/SelectEditor';
import { selectOrderOptions } from '../../slices/masterData/selectOrderEntities';
import Constants from '../../Constants';
import { capitalizeFirstLetter } from '../../functions';
import { Badge } from '@chakra-ui/react';
import { selectOrderStatusMap } from '../../slices/order-status-map/orderStatusMapSlice';

export const rowKeyGetter = row => row.id;

const polCols = {
  inputProductUid: {
    key: 'input_product_uid',
    name: 'Product',
    width: 250,
    resizable: true,
    frozen: true,
    formatter: ({ row }) => {
      const { inputProductOptions } = useSelector(selectOrderOptions);
      const match = findOptionMatch(inputProductOptions, row?.input_product_uid);
      return match ? match.name : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { inputProductOptions } = useSelector(selectOrderOptions);
      const filteredinputProductOptions = inputProductOptions.filter(item => item.delisted === false|| item.delisted === null); 
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.input_product?.name}
          onChange={value => {
            // Find matching options object:
            const match = findOptionMatch(filteredinputProductOptions, value);
            onRowChange(
              {
                ...row,
                input_product_uid: match?.uid,
                packer_ingredient_description: match.description,
              },
              true
            );
          }}
          options={filteredinputProductOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  productDesc: {
    key: 'packer_ingredient_description',
    name: 'Product Description',
    width: 250,
    resizable: true,
    frozen: true,
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  priceType: {
    key: 'price_type',
    name: 'Order Type',
    width: 230,
    resizable: true,
    frozen: true,
    formatter: ({ row }) => {
      const { orderTypeOptions } = useSelector(selectOrderOptions);
      const match = findOptionMatch(orderTypeOptions, row?.price_type);
      return capitalizeFirstLetter(match?.value || '');
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { orderTypeOptions } = useSelector(selectOrderOptions);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.price_type}
          onChange={value => {
            // Find matching options object:
            const match = findOptionMatch(orderTypeOptions, value);
            onRowChange(
              {
                ...row,
                price_type: match?.value,
              },
              true
            );
          }}
          options={orderTypeOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  buyQuantity: {
    key: 'buy_quantity',
    name: 'Quantity',
    width: 80,
    editor: props => (
      <InputEditor
        {...props}
        onRowChange={row => {
          // eslint-disable-next-line react/prop-types
          props.onRowChange({ ...row, buy_quantity: row.buy_quantity, sell_quantity: row.buy_quantity });
        }}
        type="number"
      />
    ),
    editorOptions: {
      editOnClick: true,
    },
  },
  expectedProdDate: {
    key: 'expected_production_date',
    name: 'Expected Production Date',
    width: 145,
    resizable: true,
    frozen: true,
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  buyPrice: { 
    key: 'buy_price_per_unit', 
    name: 'Buy Unit Price', 
    width: 100, 
    resizable: true, 
    frozen: true, 
    editor: props => <InputEditor {...props} type="number" />,
    editorOptions: {
      editOnClick: true,
    },
  },
  pricePerUnit: {
    key: 'price_per_unit',
    name: 'FOB Price',
    width: 100,
    resizable: true,
    frozen: true,
    editor: props => <InputEditor {...props} type="number" />,
    editorOptions: {
      editOnClick: true,
    },
  },
};

const voyageCols = {
  transhipPort: {
    key: 'tranship_port_id',
    name: 'Transship Port',
    width: 90,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { portOptions } = useSelector(selectOrderOptions);
      const match = findOptionMatch(portOptions, row?.tranship_port_id);
      return match?.name ? match?.name : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { portOptions } = useSelector(selectOrderOptions);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.tranship_port_id}
          onChange={value => {
            // Find matching options object:
            const match = findOptionMatch(portOptions, value);
            onRowChange(
              {
                ...row,
                tranship_port_id: match?.id,
              },
              true
            );
          }}
          options={portOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  portOfDischarge: {
    key: 'discharge_port',
    name: 'Port Of Discharge',
    width: 90,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { portOptions } = useSelector(selectOrderOptions);
      const match = findOptionMatch(portOptions, row?.discharge_port_id);
      return match?.name ? match?.name : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { portOptions } = useSelector(selectOrderOptions);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.discharge_port_id}
          onChange={value => {
            // Find matching options object:
            const match = findOptionMatch(portOptions, value);
            onRowChange(
              {
                ...row,
                discharge_port_id: match?.id,
              },
              true
            );
          }}
          options={portOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  portOfLoading: {
    key: 'load_port',
    name: 'Port Of Loading',
    width: 90,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { portOptions } = useSelector(selectOrderOptions);
      const match = findOptionMatch(portOptions, row?.load_port_id);
      return match?.name ? match?.name : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { portOptions } = useSelector(selectOrderOptions);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.load_port_id}
          onChange={value => {
            // Find matching options object:
            const match = findOptionMatch(portOptions, value);
            onRowChange(
              {
                ...row,
                load_port_id: match?.id,
              },
              true
            );
          }}
          options={portOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  shippingLine: {
    key: 'shipping_line_id',
    name: 'Shipping Line',
    width: 90,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { shippingLineOptions } = useSelector(selectOrderOptions);
      const match = findOptionMatch(shippingLineOptions, row?.shipping_line_id);
      return match?.name ? match?.name : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { shippingLineOptions } = useSelector(selectOrderOptions);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.shipping_line_id}
          onChange={value => {
            // Find matching options object:
            const match = findOptionMatch(shippingLineOptions, value);
            onRowChange(
              {
                ...row,
                shipping_line_id: match?.id,
              },
              true
            );
          }}
          options={shippingLineOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  eta: {
    key: 'eta',
    name: 'ETA',
    width: 100,
    resizable: true,
    frozen: false,
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  etd: {
    key: 'etd',
    name: 'ETD',
    width: 100,
    resizable: true,
    frozen: false,
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  vesselName: {
    key: 'vessel_name',
    name: 'Vessel Name',
    width: 150,
    resizable: true,
    frozen: false,
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  voyageNum: {
    key: 'voyage_number',
    name: 'Voyage Number',
    width: 100,
    resizable: true,
    frozen: false,
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
};
const poCols = {
  selectColumn: SelectColumn,
  actions: {
    key: 'actions',
    name: 'Action',
    formatter: OrderActionsFormatter,
    width: 80,
    frozen: true,
  },
  actionsAus: {
    key: 'actions',
    name: 'Action',
    formatter: OrderActionsFormatter,
    width: 80,
    frozen: false,
  },
  bolNum: {
    key: 'bill_of_lading.bol_number',
    name: 'Bol Number',
    width: 130,
    resizable: true,
    sortable: true,
    frozen: false,
    formatter: ({ row }) => row?.bill_of_lading?.bol_number || '',
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  bookNum: {
    key: 'bill_of_lading.booking_reference_number',
    name: 'Booking Reference Number',
    width: 180,
    resizable: true,
    frozen: false,
    sortable: true,
    formatter: ({ row }) => row?.bill_of_lading?.booking_reference_number || '',
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  containerNum: {
    key: 'bill_of_lading.container_number',
    name: 'Container Number',
    width: 150,
    resizable: true,
    frozen: false,
    sortable: true,
    formatter: ({ row }) => row?.bill_of_lading?.container_number || '',
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  exportNum: {
    key: 'bill_of_lading.export_declaration_number',
    name: 'Export Dec Number',
    width: 150,
    resizable: true,
    frozen: false,
    sortable: true,
    formatter: ({ row }) => row?.bill_of_lading?.export_declaration_number || '',
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  poId: {
    key: 'internal_po_number',
    name: 'PO#',
    sortable: true,
    width: 140,
    frozen: true,
    resizable: true,
  },
  poIdAus: {
    key: 'internal_po_number',
    name: 'PO#',
    sortable: true,
    width: 140,
    frozen: false,
    resizable: true,
  },
  grinderPoNumber: {
    key: 'grinder_po_number',
    name: 'Grinder PO#',
    width: 140,
    resizable: true,
    frozen: true,
    sortable: true,
  },
  grinderPoNumberAus: {
    key: 'grinder_po_number',
    name: 'Grinder PO#',
    width: 180,
    resizable: true,
    frozen: false,
    sortable: true,
  },
  grinderName: {
    key: 'grinder.name',
    name: 'Grinder ',
    width: 180,
    sortable: true,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => row?.grinder?.name || '',
  },
  OrderTypeAus: {
    key: 'price_type',
    name: 'Order Type',
    width: 180,
    resizable: true,
    frozen: true,
    formatter: ({ row }) => {
      const { orderTypeOptions } = useSelector(selectOrderOptions);
      const match = findOptionMatch(orderTypeOptions, row?.price_type);
      return capitalizeFirstLetter(match?.value || '');
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { orderTypeOptions } = useSelector(selectOrderOptions);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.price_type}
          onChange={value => {
            // Find matching options object:
            const match = findOptionMatch(orderTypeOptions, value);
            onRowChange(
              {
                ...row,
                price_type: match?.value,
              },
              true
            );
          }}
          options={orderTypeOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  deliveryDate: {
    key: 'delivery_date',
    name: 'Date of Delivery',
    width: 145,
    resizable: true,
    frozen: true,
    sortable: true,
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  shipmentDate: {
    key: 'shipment_date',
    name: 'Shipment Date',
    width: 145,
    resizable: true,
    frozen: true,
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  productionDate: {
    key: 'production_date',
    name: 'Production Date',
    width: 145,
    resizable: true,
    frozen: true,
    editor: DatePickerEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  transportCostPerUnit: {
    key: 'transport_cost_per_unit',
    name: 'Transport Cost Per Unit',
    width: 130,
    resizable: true,
    frozen: true,
    editor: props => <InputEditor {...props} type="number" />,
    editorOptions: {
      editOnClick: true,
    },
  },
  deliveryDateDay: {
    key: 'delivery_date_day',
    name: 'Day',
    width: 10,
    frozen: true,
    formatter: ({ row }) => (row?.delivery_date ? moment(row?.delivery_date).format('ddd') : ''),
  },
  weekEnding: {
    key: 'week_ending',
    name: 'Week Ending',
    width: 70,
    resizable: true,
    frozen: true,
    formatter: ({ row }) =>
      row?.delivery_date
        ? moment(row?.delivery_date).endOf('week').add(1, 'day').format(Constants.DEFAULT_READ_DATE_FORMAT)
        : '',
  },
  scheduledDeliveryTime: {
    key: 'scheduled_delivery_time',
    name: 'Delivery Time',
    width: 120,
    resizable: true,
    sortable: true,
    frozen: true,
    formatter: ({ row }) => (row?.scheduled_delivery_time ? row?.scheduled_delivery_time.substring(0, 5) : ''),
    // eslint-disable-next-line react/prop-types
    editor: props => <InputEditor {...props} type="time" step="300" />,
    editorOptions: {
      editOnClick: true,
    },
  },
  packerPlant: {
    key: 'packer_plant',
    name: 'Establishment',
    width: 230,
    resizable: true,
    sortable: true,
    frozen: true,
    formatter: ({ row }) => {
      const { packerPlantOptions } = useSelector(selectOrderOptions);
      const packerPlant = findOptionMatch(packerPlantOptions, row?.packer_plant_id);
      return packerPlant?.name || '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { packerPlantOptions, transportOptions } = useSelector(selectOrderOptions);
      const filteredPackerPlantOptions = packerPlantOptions.filter(item => item.delisted === false|| item.delisted === null);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.packer_plant_id}
          onChange={value => {
            const transportCost = transportOptions.find(item => {
              return item.grinder_uid === row?.grinder_uid && item.packer_plant_id === value;
            });
            // Find matching options object:
            const packerPlant = findOptionMatch(filteredPackerPlantOptions, value);
            onRowChange(
              {
                ...row,
                packer_plant_id: packerPlant?.id,
                transport_cost_per_unit: transportCost ? transportCost.cost.toFixed(4) : 0,
              },
              true
            );
          }}
          options={filteredPackerPlantOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  packerPlantId: {
    key: 'packer_plant_id',
    name: 'Est Number',
    width: 120,
    resizable: true,
    frozen: false,
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { packerPlantOptions, transportOptions } = useSelector(selectOrderOptions);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.packer_plant_id}
          onChange={value => {
            // Find matching options object:
            const packerPlant = findOptionMatch(packerPlantOptions, value);
            const transportCost = transportOptions.find(item => {
              return item.grinder_uid === row?.grinder_uid && item.packer_plant_id === value;
            });
            onRowChange(
              {
                ...row,
                packer_plant_id: packerPlant?.id,
                transport_cost_per_unit: transportCost ? transportCost.cost.toFixed(4) : 0,
              },
              true
            );
          }}
          options={packerPlantOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  packerPlantAus: {
    key: 'packer_plant',
    name: 'Establishment',
    width: 170,
    resizable: true,
    sortable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { packerPlantOptions } = useSelector(selectOrderOptions);
      const packerPlant = findOptionMatch(packerPlantOptions, row?.packer_plant_id);
      return packerPlant?.name || '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { packerPlantOptions, transportOptions } = useSelector(selectOrderOptions);
      const filteredPackerPlantOptions = packerPlantOptions.filter(item => item.delisted === false|| item.delisted === null);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.packer_plant_id}
          onChange={value => {
            // Find matching options object:
            const packerPlant = findOptionMatch(filteredPackerPlantOptions, value);
            const transportCost = transportOptions.find(item => {
              return item.grinder_uid === row?.grinder_uid && item.packer_plant_id === value;
            });
            onRowChange(
              {
                ...row,
                packer_plant_id: packerPlant?.id,
                transport_cost_per_unit: transportCost ? transportCost.cost.toFixed(4) : 0,
              },
              true
            );
          }}
          options={filteredPackerPlantOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  packer: {
    key: 'packer',
    name: 'Packer',
    width: 170,
    resizable: true,
    sortable: true,
    frozen: true,
    formatter: ({ row }) => {
      const { packerOptions, packerPlantOptions } = useSelector(selectOrderOptions);
      const packerPlant = findOptionMatch(packerPlantOptions, row?.packer_plant_id);
      const packer = findOptionMatch(packerOptions, packerPlant?.packer_id);
      return packer?.name || '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { packerOptions, packerPlantOptions, transportOptions } = useSelector(selectOrderOptions);
      // eslint-disable-next-line react/prop-types
      const packerPlant = findOptionMatch(packerPlantOptions, row?.packer_plant_id);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={packerPlant?.packer_id}
          onChange={value => {
            const transportCost = transportOptions.find(item => {
              return (
                item.grinder_uid === row?.grinder_uid &&
                item.packer_plant_id === packerPlantOptions.find(pp => pp.packer_id === value)?.id
              );
            });
            onRowChange(
              {
                ...row,
                packer_plant_id: packerPlantOptions.find(pp => pp.packer_id === value)?.id,
                transport_cost_per_unit: transportCost ? transportCost.cost.toFixed(4) : 0,
              },
              true
            );
          }}
          options={packerOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  carrier: {
    key: 'transporter',
    name: 'Carrier',
    width: 90,
    resizable: true,
    frozen: true,
    formatter: ({ row }) => {
      const { transporterOptions } = useSelector(selectOrderOptions);
      const match = findOptionMatch(transporterOptions, row?.transporter_id);
      return match?.name ? match?.name : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { transporterOptions } = useSelector(selectOrderOptions);
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.transporter_id}
          onChange={value => {
            // Find matching options object:
            const match = findOptionMatch(transporterOptions, value);
            onRowChange(
              {
                ...row,
                transporter_id: match?.id,
              },
              true
            );
          }}
          options={transporterOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  packerSoNumber: {
    key: 'packer_so_number',
    name: 'Packer SO#',
    width: 120,
    frozen: false,
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  packerInvoice: {
    key: 'packer_invoice_number',
    name: 'Packer Invoice#',
    width: 120,
    frozen: false,
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  orderDate: {
    key: 'order_date',
    name: 'Creation Date',
    width: 120,
    sortable: true,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => (row?.order_date ? moment(row?.order_date).format(Constants.DEFAULT_READ_DATE_FORMAT) : ''),
  },
  healthCertificate: {
    key: 'export_details.health_certificate_number',
    name: 'Health Certificate#',
    width: 140,
    resizable: true,
    sortable: true,
    frozen: false,
    formatter: ({ row }) => row?.export_details?.health_certificate_number || '',
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  coldstore: {
    key: 'coldstore_details.cold_store_id',
    name: 'Coldstore',
    width: 150,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { coldstoreOptions } = useSelector(selectOrderOptions);
      const match = coldstoreOptions.find(o => o.id === row?.coldstore_details?.cold_store_id);
      return match?.location_name ? match?.location_name : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { coldstoreOptions } = useSelector(selectOrderOptions);
      const formattedOptions = [];
      coldstoreOptions.forEach(el => {
        formattedOptions.push({ label: el.location_name, value: el.id });
      });
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.coldstore_details?.cold_store_id}
          onChange={value => {
            // Find matching options object:
            const match = coldstoreOptions.find(o => o.id === value);
            onRowChange(
              {
                ...row,
                coldstore_details: {
                  ...row.coldstore_details,
                  cold_store_id: match?.id,
                },
              },
              true
            );
          }}
          options={formattedOptions}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  lotNum: {
    key: 'coldstore_details.lot_numbers',
    name: 'Lot Numbers',
    width: 140,
    resizable: true,
    frozen: true,
    formatter: ({ row }) => row?.coldstore_details?.lot_numbers?.join(',') || '',
    editor: props => (
      <InputEditor
        {...props}
        onRowChange={row => {
          // eslint-disable-next-line react/prop-types
          props.onRowChange({
            ...row,
            coldstore_details: {
              ...row.coldstore_details,
              lot_numbers: row.coldstore_details?.lot_numbers?.split(',')
            }
          });
        }}
        type="text"
      />
    ),
    editorOptions: {
      editOnClick: true,
    },
  },
  containerDepot: {
    key: 'export_details.container_depot',
    name: 'Container Depot',
    width: 100,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { formFieldOptions } = useSelector(selectOrderOptions);
      const matches = formFieldOptions.filter(o => o.type === 'container_depot');
      const match = matches.find(o => o.value === row?.export_details?.container_depot);
      return match?.value ? match?.value : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { formFieldOptions } = useSelector(selectOrderOptions);
      const matches = formFieldOptions.filter(o => o.type === 'container_depot');
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.export_details?.container_depot}
          onChange={value => {
            // Find matching options object:
            const match = matches.find(o => o.value === value);
            onRowChange(
              {
                ...row,
                export_details: {
                  ...row.export_details,
                  container_depot: match?.value,
                },
              },
              true
            );
          }}
          options={matches}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  containerReturn: {
    key: 'export_details.container_return',
    name: 'Container Return',
    width: 100,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { formFieldOptions } = useSelector(selectOrderOptions);
      const matches = formFieldOptions.filter(o => o.type === 'container_return');
      const match = matches.find(o => o.value === row?.export_details?.container_return);
      return match?.value ? match?.value : '';
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { formFieldOptions } = useSelector(selectOrderOptions);
      const matches = formFieldOptions.filter(o => o.type === 'container_return');
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.export_details?.container_return}
          onChange={value => {
            // Find matching options object:
            const match = matches.find(o => o.value === value);
            onRowChange(
              {
                ...row,
                export_details: {
                  ...row.export_details,
                  container_return: match?.value,
                },
              },
              true
            );
          }}
          options={matches}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  containerType: {
    key: 'load_container_details',
    name: 'Container Type',
    width: 130,
    resizable: true,
    frozen: false,
    formatter: ({ row }) => {
      const { formFieldOptions } = useSelector(selectOrderOptions);
      const matches = formFieldOptions.filter(o => o.type === 'container');
      const match = matches.find(o => o.value === row?.load_container_details);
      return match?.value ? match?.value : row?.load_container_details;
    },
    // eslint-disable-next-line react/prop-types
    editor: ({ row, onRowChange, ...props }) => {
      const { formFieldOptions } = useSelector(selectOrderOptions);
      const matches = formFieldOptions.filter(o => o.type === 'container');
      return (
        <SelectEditor
          {...props}
          // eslint-disable-next-line react/prop-types
          defaultValue={row?.load_container_details}
          onChange={value => {
            // Find matching options object:
            const match = matches.find(o => o.value === value);
            onRowChange(
              {
                ...row,
                load_container_details: match?.value,
              },
              true
            );
          }}
          options={matches}
        />
      );
    },
    editorOptions: {
      editOnClick: true,
    },
  },
  isf: {
    key: 'export_details.isf',
    name: 'ISF',
    width: 150,
    resizable: true,
    sortable: true,
    frozen: false,
    formatter: ({ row }) => row?.export_details?.isf || '',
    editor: InputEditor,
    editorOptions: {
      editOnClick: true,
    },
  },
  status: {
    key: 'status',
    name: 'Status',
    width: 140,
    resizable: true,
    frozen: true,
    formatter: ({ row }) => {
      const orderStatus = useSelector(selectOrderStatusMap);
      return (
        <Badge lineHeight="18px" borderRadius="3px" px="5px" colorScheme={row.status === 'cancelled' ? 'red' : 'gray'}>
          {orderStatus[row.status]}
        </Badge>
      )
  },
},
  lines: {
    key: 'lines',
    name: 'Lines',
    resizable: true,
    width: 1000,
    frozen: true,
    columns: [
      polCols.inputProductUid,
      polCols.buyQuantity,
      polCols.buyPrice,
      polCols.pricePerUnit,
      polCols.priceType,
      polCols.expectedProdDate,
    ],
    rowKey: 'lines',
    cellClass() {
      return 'nested__results-grid-cell';
    },
// eslint-disable-next-line react/prop-types
formatter({ row, column: { columns, rowKey } }) {
  return (
    <DataGrid
      // eslint-disable-next-line react/prop-types
      columns={columns.map(({ editor, ...c }) => ({
        ...c,
      }))}
      // eslint-disable-next-line react/prop-types
      rows={row[rowKey]}
      rowKeyGetter={rowKeyGetter}
      rowClass={rowClass}
    />
  );
},
// eslint-disable-next-line react/prop-types
editor: ({ row, onRowChange, column: { columns, rowKey } }) => {
  const handleRowUpdate = (updatedRows) => {    
    const updatedRow = updatedRows.find((newRow, index) => {
      const oldRow = row[rowKey][index];
      return JSON.stringify(oldRow) !== JSON.stringify(newRow);
    });

    if (updatedRow) {
      const originalRow = row[rowKey].find(r => r.id === updatedRow.id); // Adjust for unique key
      const updatedFields = Object.keys(updatedRow).filter(
        key => updatedRow[key] !== originalRow[key]
      );
      if (updatedFields.includes('price_per_unit')) {
        const pricePerUnit = updatedRow.price_per_unit; 
        const transportCostPerUnit = row.transport_cost_per_unit;          
        // Calculate buy_price_per_unit based on product_type
        if (row.product_type === Constants.PRODUCT_TYPE_LIST.BEEF) {
          updatedRow.buy_price_per_unit = pricePerUnit + transportCostPerUnit;
        } else if (row.product_type === Constants.PRODUCT_TYPE_LIST.PORK) {
          const groupHousedPremiumRate = updatedRow.group_housed_premium_rate || 0; // Handle undefined case
          updatedRow.buy_price_per_unit = 
            pricePerUnit + transportCostPerUnit + groupHousedPremiumRate;
        }          
      }
    }
    onRowChange({ ...row, [rowKey]: updatedRows }, true);
  };
  return (
    <DataGrid
      // eslint-disable-next-line react/prop-types
      columns={columns}
      // eslint-disable-next-line react/prop-types
      rows={row[rowKey]}
      rowKeyGetter={rowKeyGetter}
      onGridRowUpdate={handleRowUpdate}
      rowClass={rowClass}
    />
  );
},
  editorOptions: {
  editOnClick: true,
    },
  },
linesAusLogistics: {
  key: 'lines_aus_logistics',
    name: 'Lines',
      resizable: true,
        width: 600,
          frozen: false,
            columns: [polCols.inputProductUid, polCols.productDesc, polCols.buyQuantity],
              rowKey: 'lines',
                cellClass() {
    return 'nested__results-grid-cell';
  },
  // eslint-disable-next-line react/prop-types
  formatter({ row, column: { columns, rowKey } }) {
    return (
      <DataGrid
        // eslint-disable-next-line react/prop-types
        columns={columns.map(({ editor, ...c }) => ({
          ...c,
        }))}
        // eslint-disable-next-line react/prop-types
        rows={row[rowKey]}
        rowKeyGetter={rowKeyGetter}
        rowClass={rowClass}
      />
    );
  },
  // eslint-disable-next-line react/prop-types
  editor: ({ row, onRowChange, column: { columns, rowKey } }) => {
    return (
      <DataGrid
        // eslint-disable-next-line react/prop-types
        columns={columns}
        // eslint-disable-next-line react/prop-types
        rows={row[rowKey]}
        rowKeyGetter={rowKeyGetter}
        onGridRowUpdate={rows => onRowChange({ ...row, [rowKey]: rows }, true)}
        rowClass={rowClass}
      />
    );
  },
    editorOptions: {
    editOnClick: true,
    },
},
voyage: {
  key: 'voyage',
    name: 'Voyage',
      resizable: true,
        width: 700,
          columns: [
            voyageCols.vesselName,
            voyageCols.portOfLoading,
            voyageCols.portOfDischarge,
            voyageCols.shippingLine,
            voyageCols.transhipPort,
            voyageCols.voyageNum,
            voyageCols.etd,
            voyageCols.eta,
          ],
            rowKey: 'voyage',
              cellClass() {
    return 'nested__results-grid-cell';
  },
  // eslint-disable-next-line react/prop-types
  formatter({ row, column: { columns, rowKey } }) {
    return (
      <DataGrid
        // eslint-disable-next-line react/prop-types
        columns={columns.map(({ editor, ...c }) => ({
          ...c,
        }))}
        // eslint-disable-next-line react/prop-types
        rows={row[rowKey]}
        rowKeyGetter={rowKeyGetter}
      />
    );
  },
  // eslint-disable-next-line react/prop-types
  editor: ({ row, onRowChange, column: { columns, rowKey } }) => {
    return (
      <DataGrid
        // eslint-disable-next-line react/prop-types
        columns={columns}
        // eslint-disable-next-line react/prop-types
        rows={row[rowKey]}
        rowKeyGetter={rowKeyGetter}
        onGridRowUpdate={rows => onRowChange({ ...row, [rowKey]: rows }, true)}
      />
    );
  },
    editorOptions: {
    editOnClick: true,
    },
},
};

const defaultCols = [
  poCols.selectColumn,
  poCols.actions,
  poCols.poId,
  poCols.deliveryDate,
  poCols.grinderPoNumber,
  poCols.status,
  poCols.lines,
];

const ausLogisticsCols = [
  poCols.selectColumn,
  poCols.actionsAus,
  poCols.poIdAus,
  poCols.grinderPoNumberAus,
  poCols.orderDate,
  poCols.grinderName,
  poCols.packerPlantAus,
  poCols.packerPlantId,
  poCols.OrderTypeAus,
  poCols.healthCertificate,
  poCols.packerSoNumber,
  poCols.packerInvoice,
  poCols.isf,
  poCols.bolNum,
  poCols.bookNum,
  poCols.containerNum,
  poCols.exportNum,
  poCols.containerDepot,
  poCols.containerReturn,
  poCols.containerType,
  poCols.coldstore,
  (status => {
    const statusCopy = { ...status };
    delete statusCopy.frozen;
    return statusCopy;
  })(poCols.status),
  poCols.voyage,
  poCols.linesAusLogistics,
];

const chicagoCols = [
  poCols.selectColumn,
  poCols.actions,
  poCols.grinderPoNumber,
  poCols.deliveryDate,
  poCols.scheduledDeliveryTime,
  poCols.packer,
  poCols.packerPlant,
  poCols.lotNum,
  poCols.productionDate,
  poCols.transportCostPerUnit,
  poCols.status,
  poCols.poId,
  poCols.lines,
];

export default {
  default: {
    value: 'default',
    label: 'Default View',
    columns: defaultCols,
  },
  chicago: {
    value: 'chicago',
    label: 'Chicago Form',
    columns: chicagoCols,
  },
  ausLogistics: {
    value: 'ausLogistics',
    label: 'Logistics',
    columns: ausLogisticsCols.map((col, index) => ({
      ...col,
      frozen: index < 4,
      cellClass() {
        return index < 4 ? `frozen__cell ${invoke(col, 'cellClass', '')}` : invoke(col, 'cellClass', '');
      },
    })),
  },
};

export const rowHeight = props => HEADER_ROW_HEIGHT + props.row.lines.length * ROW_HEIGHT;

export const gridHeight = rows =>
  HEADER_ROW_HEIGHT +
  rows.reduce((count, row) => count + HEADER_ROW_HEIGHT + row.lines.length * ROW_HEIGHT + HEIGHT_BUFFER, 0);

export const rowClass = row => (row.status === 'cancelled' || row.active === false ? `status-cancelled` : null);

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export const difference = (origObj_, newObj_) => {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, (result, value, key) => {
      if (!isEqual(value, origObj[key])) {
        let resultKey;
        if (isArray(origObj)) {
          resultKey = arrayIndexCounter;
          arrayIndexCounter += 1;
        } else {
          resultKey = key;
        }
        // eslint-disable-next-line no-param-reassign
        result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value;
        if (resultKey === 'export_details' && origObj[key]) {
          // eslint-disable-next-line no-param-reassign
          result[resultKey].purchase_order_id = origObj[key].purchase_order_id;
        }

        // eslint-disable-next-line no-param-reassign
        result.id = origObj.id;
      }
    });
  }
  return changes(newObj_, origObj_);
};
