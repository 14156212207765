import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Datetime from 'react-datetime';
// LOCAL DEPS
import './PurchaseOrderForm.scss';
import Constants from '../../Constants';
import {
  constructBuyerOptions,
  validateSelectableContractEndDate,
  validateSelectableContractStartDate,
} from '../../helpers';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import { fetchUsers } from '../../actions/actions_users';
import { getDefaultValueFromConfigs, getIngredientOptions, isFeatureEnabled } from '../../utils';
import SelectField from '../basic/SelectField';
import ProductionDetails from './order-form/ProductionDetails/ProductionDetails';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IoAdd, IoInformationCircleOutline, IoTrashOutline } from 'react-icons/io5';
import { buyersSelector } from '../../reducers/reducer_users';
import Tooltip from '../quality/_components/Tooltip';

const renderErrorClassName = (error, numberValue) => {
  return error && (isNaN(numberValue) || numberValue === '') ? 'error' : '';
};

class SingleIngredientForm extends Component {
  static propTypes = {
    handleSelectValue: PropTypes.func,
    dispatch: PropTypes.func,
    formValues: PropTypes.instanceOf(Object),
    price_types: PropTypes.arrayOf(PropTypes.object),
    packer_plants: PropTypes.arrayOf(PropTypes.object),
    input_products: PropTypes.arrayOf(PropTypes.object),
    grinders: PropTypes.arrayOf(PropTypes.object),
    transporters: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.instanceOf(Object),
    endUsers: PropTypes.arrayOf(Object),
    configs: PropTypes.arrayOf(Object),
  };

  constructor(props) {
    super(props);
    this.removeAllWarnings = this.removeAllWarnings.bind(this);
    this.state = {
      dateWarnings: [],
    };
  }

  static TRANSPORT_MIN = 0.01;

  static TRANSPORT_MAX = 0.5;

  removeAllWarnings() {
    this.setState({ dateWarnings: [] });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchEntitiesData());
    dispatch(fetchUsers());
    window.addEventListener('keypress', this.removeAllWarnings, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.removeAllWarnings);
  }

  setFabricationDate(index, prop, e) {
    if (prop === 'expectedProductionDate' && e._isAMomentObject) {
      if (e.isBefore(moment().subtract(14, 'days'))) {
        const { dateWarnings } = this.state;
        const currentWarnings = JSON.parse(JSON.stringify(dateWarnings));
        currentWarnings.push(index);
        this.setState({ dateWarnings: currentWarnings });
      }
    }
    this.props.handleLineSelectValue(index, 'expectedProductionDate', e);
  }

  removeWarning(index) {
    const { dateWarnings } = this.state;
    const currentWarnings = JSON.parse(JSON.stringify(dateWarnings));
    currentWarnings.splice(currentWarnings.indexOf(index));
    this.setState({ dateWarnings: currentWarnings });
  }

  handleSelect = (fieldName, option, index) => {
    const { handleSelectValue } = this.props;
    handleSelectValue(fieldName, option, index);
    this.setState({}); // trigger re-render for page
  };

  handleProductionDetailsChange = (lineIdx, lineId, productionDetails, toDeleteProductionDetailsIds) => {
    const { submitProductionDetails } = this.props;
    submitProductionDetails(lineIdx, lineId, productionDetails, toDeleteProductionDetailsIds);
  };
  handleUpdateLine = (field, lineIdx, value) => {
    this.props.handleLineSelectValue(lineIdx, 'estimated', value);
  };
  render() {
    const {
      formValues,
      price_types: priceTypes,
      packer_plants: packerPlants,
      grinders,
      users,
      user,
      transporters,
      input_products: inputProducts,
      endUsers,
      configs,
      units_of_measure: unitsOfMeasure,
    } = this.props;
    const { productType } = formValues;

    let filteredInputProducts = inputProducts;
    let filteredPackerPlants = packerPlants;

    if (productType) {
      filteredInputProducts = inputProducts.filter(ip => ip.product_type === productType);
      filteredPackerPlants = packerPlants
        .filter(pp => pp.product_type === productType)
        .filter(option =>
          option.origin_country
            ? Constants.AMERICA_DOMESTIC_COUNTRIES.includes(option.origin_country.toLowerCase())
            : true
        );
    }

    const shippingDateInputProps = {
      placeholder: 'Shipping',
      className: 'form-control m-input',
    };
    const deliveryDateInputProps = {
      placeholder: 'Scheduled Delivery Date',
      className: 'form-control m-input',
    };
    const deliveryTimeInputProps = {
      placeholder: 'Time',
      className: 'form-control m-input',
    };
    const productionDateInputProps = {
      placeholder: 'Production',
      className: 'form-control m-input',
    };
    const fabricationDateInputProps = {
      placeholder: 'Fabrication',
      className: 'form-control m-input',
    };
    const recurringDateStartInputProps = {
      placeholder: 'Recurring Start',
      className: 'form-control m-input',
    };
    const recurringDateEndInputProps = {
      placeholder: 'Recurring End',
      className: 'form-control m-input',
    };
    const contractStartDateInputProps = {
      placeholder: 'Contract Start',
      className: 'form-control m-input',
    };
    const contractEndDateInputProps = {
      placeholder: 'Contract End',
      className: 'form-control m-input',
    };

    const valid = function (current) {
      return current.isAfter(Datetime.moment().subtract(1, 'day'));
    };

    const priceTypeOptions = [];

    priceTypes.forEach(function (element) {
      const capitalized = element.value.charAt(0).toUpperCase() + element.value.slice(1);
      priceTypeOptions.push({ value: element.value, label: capitalized });
    });

    const packerPlantOptions = [];
    filteredPackerPlants.forEach(function (element) {
      packerPlantOptions.push({ value: element.id, label: element.name });
    });

    const grinderOptions = [];
    grinders
      .filter(option =>
        option.destination_country
          ? Constants.AMERICA_DOMESTIC_COUNTRIES.includes(option.destination_country.toLowerCase())
          : true
      )
      .forEach(function (element) {
        grinderOptions.push({ value: element.uid, label: element.name });
      });

    const transporterOptions = [];
    transporters.forEach(function (element) {
      transporterOptions.push({
        value: element.id,
        label: `${element.name} (${element.scac})`,
      });
    });

    const buyerOptions = constructBuyerOptions(buyersSelector(users), user);

    const dropTrailerOptions = [
      { value: '', label: 'None' },
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ];

    const inputProductOptions = getIngredientOptions(filteredInputProducts, [
      { filter_key: 'COUNTRY', filter_args: ['USA'] },
    ]);
    const isActiveOrderTypeNotes = isFeatureEnabled(configs, 'show_order_type_notes');
    const isActiveEstimatedProductionDetail = isFeatureEnabled(configs, 'show_estimated_production_detail');
    const defaultEndUserName = getDefaultValueFromConfigs(configs, 'end_user_default');
    const endUserOptions = endUsers
      // eslint-disable-next-line no-shadow
      .filter(endUser => endUser.is_active === true)
      .map(_endUser => ({
        label: _endUser.legal_name,
        value: _endUser.id,
        grinders_uids: _endUser.grinders_uids,
      }));
    // match default fields
    // eslint-disable-next-line no-shadow
    const defaultEndUser = endUserOptions.filter(endUser => endUser.label === defaultEndUserName);
    if (
      !formValues.endUserId &&
      defaultEndUser.length === 1 &&
      typeof defaultEndUser[0] === 'object' &&
      defaultEndUser[0].value !== formValues.endUserId
    ) {
      this.handleSelect('endUserId', defaultEndUser[0].value);
    }
    const showTransportWarning =
      formValues.transportCostPerUnit !== '' &&
      (formValues.transportCostPerUnit < SingleIngredientForm.TRANSPORT_MIN ||
        formValues.transportCostPerUnit > SingleIngredientForm.TRANSPORT_MAX);

    return (
      <Box className="purchase-order-form">
        {showTransportWarning && (
          <Box w="50%" my={4}>
            <Alert status="warning">
              <AlertIcon />
              <AlertDescription>
                The current transport cost per unit has been flagged as potentially being too small({'< 0.01'}) or
                large({'> 0.5'}).
              </AlertDescription>
            </Alert>
          </Box>
        )}
        <Grid templateColumns="repeat(4, 1fr)" gap={8}>
          <GridItem>
            <label htmlFor="product_type">
              <span>Product Type</span>
              &nbsp;
              <Tooltip
                content="Specifies whether the order is for Beef or Pork to categorize the meat product."
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <SelectField
              name="select-packer"
              value={
                Constants.PRODUCT_TYPES.find(option => option.value === formValues.productType) ||
                Constants.PRODUCT_TYPE_LIST.BEEF
              }
              options={Constants.PRODUCT_TYPES}
              placeholder="Product Type"
              onChange={option => this.handleSelect('productType', option)}
            />
          </GridItem>
          <GridItem>
            <label htmlFor="end_users">
              <span>End User</span>
              &nbsp;
              <Tooltip
                content="Identifies the final recipient to ensure the product meets specific requirements."
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <SelectField
              name="select-end-user"
              value={endUserOptions.find(e => e.value === formValues.endUserId)}
              options={endUserOptions}
              placeholder="End User"
              onChange={option => this.handleSelect('endUserId', option)}
            />
          </GridItem>
          <GridItem rowStart={2}>
            <label htmlFor="est_id">
              <span>Establishment Number</span>
              &nbsp;
              <Tooltip content="Records the official number for traceability and compliance." placement="right">
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <input
              name="est_id"
              className="form-control"
              value={formValues.estId || ''}
              placeholder="123"
              onChange={option => this.handleSelect('estId', option)}
            />
          </GridItem>
          <GridItem rowStart={2}>
            <label htmlFor="select-packer">
              <span>Establishment</span>
              &nbsp;
              <Tooltip
                content="Captures the name of the establishment for additional context and identification."
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <SelectField
              name="select-packer"
              value={packerPlantOptions.find(option => option.value === formValues.packerPlantId) || null}
              options={packerPlantOptions}
              className={this.props.error && formValues.packerPlantId === '' ? 'error' : ''}
              placeholder="Establishment"
              onChange={option => this.handleSelect('packerPlantId', option)}
            />
          </GridItem>
          <GridItem rowStart={2}>
            <label htmlFor="select-grinder">
              <span>Grinder</span>
              &nbsp;
              <Tooltip content="Specifies the grinder used to ensure proper equipment and processes." placement="right">
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <SelectField
              name="select-grinder"
              value={grinderOptions.find(option => option.value === formValues.grinderUid) || null}
              options={grinderOptions}
              className={this.props.error && formValues.grinderUid === '' ? 'error' : ''}
              placeholder="Grinder"
              onChange={option => this.handleSelect('grinderUid', option)}
            />
          </GridItem>
          <GridItem rowStart={2}>
            <label htmlFor="transportCostPerUnit">
              <span>Freight Rate</span>
              &nbsp;
              <Tooltip
                content="Indicates the cost of transporting goods to aid in logistics planning."
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <input
              id="freight_rate"
              type="number"
              className={`form-control ${renderErrorClassName(this.props.error, formValues.transportCostPerUnit)}`}
              placeholder="Freight rate"
              onWheel={e => e.target.blur()}
              onChange={option => this.handleSelect('transportCostPerUnit', option)}
              value={formValues.transportCostPerUnit}
            />
          </GridItem>
          <GridItem rowStart={3}>
            <label className="buyer" htmlFor="select-buyer">
              <span>Buyer</span>
              &nbsp;
              <Tooltip content="Identifies the individual or organization purchasing the product." placement="right">
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <SelectField
              name="select-buyer"
              defaultValue={this.props.user.id}
              options={buyerOptions}
              placeholder="Buyer"
              onChange={option => this.handleSelect('buyer_id', option)}
              isClearable={false}
            />
          </GridItem>
          <GridItem rowStart={3}>
            <label htmlFor="select-carrier">
              <span>Carrier</span>
              &nbsp;
              <Tooltip content="Specifies the company responsible for transporting the goods." placement="right">
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <SelectField
              name="select-carrier"
              value={transporterOptions.find(option => option.value === formValues.transporterId) || null}
              options={transporterOptions}
              placeholder="Carrier"
              onChange={option => this.handleSelect('transporterId', option)}
            />
          </GridItem>
          <GridItem rowStart={3}>
            <label htmlFor="drop_trailer">
              <span>Drop Trailer</span>
              &nbsp;
              <Tooltip
                content="Indicates if drop trailer service is required for logistics planning."
                placement="right"
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <SelectField
              name="drop_trailer"
              value={dropTrailerOptions.find(trailer => trailer.value === formValues.dropTrailer) || null}
              options={dropTrailerOptions}
              placeholder="Drop Trailer"
              onChange={option => this.handleSelect('dropTrailer', option)}
            />
          </GridItem>
          <GridItem rowStart={3}>
            <label htmlFor="truck_load_no">
              <span>Truck Load Number</span>
              &nbsp;
              <Tooltip content="Records the unique number assigned to the truck load for tracking." placement="right">
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            </label>
            <input
              id="truck_load_no"
              type="text"
              className="form-control"
              placeholder="Truck Load Number"
              onChange={option => this.handleSelect('truckLoadNo', option)}
              value={formValues.truckLoadNo}
            />
          </GridItem>
          {this.props.singleOrderForm ? (
            <>
              <GridItem rowStart={4}>
                <label htmlFor="shipment_date">
                  <span>Shipment Date</span>
                  &nbsp;
                  <Tooltip content="Specifies the date when the shipment is scheduled to depart." placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </label>
                <Datetime
                  id="shipment_date"
                  isValidDate={valid}
                  inputProps={shippingDateInputProps}
                  closeOnSelect
                  value={formValues.shipmentDate}
                  onChange={option => this.handleSelect('shipmentDate', option)}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </GridItem>
              <GridItem rowStart={4}>
                <label htmlFor="delivery_date">
                  <span>Scheduled Delivery Date</span>
                  &nbsp;
                  <Tooltip
                    content="Indicates the date when the shipment is expected to be delivered."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </label>
                <Datetime
                  id="delivery_date"
                  isValidDate={valid}
                  inputProps={deliveryDateInputProps}
                  closeOnSelect
                  value={formValues.deliveryDate}
                  className={this.props.error && formValues.deliveryDate === '' ? 'error' : ''}
                  onChange={option => this.handleSelect('deliveryDate', option)}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </GridItem>
              <GridItem rowStart={4}>
                <label htmlFor="delivery_time">
                  <span>Delivery Time</span>
                  &nbsp;
                  <Tooltip
                    content="Captures the expected delivery time for scheduling and coordination."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </label>
                <Datetime
                  id="delivery_time"
                  isValidDate={valid}
                  inputProps={deliveryTimeInputProps}
                  closeOnSelect
                  value={formValues.scheduledDeliveryTime}
                  onChange={option => this.handleSelect('scheduledDeliveryTime', option)}
                  timeConstraints={{ minutes: { step: 5 } }}
                  timeFormat="HH:mm"
                  dateFormat={false}
                />
              </GridItem>
              <GridItem rowStart={4}>
                <label htmlFor="production_date">
                  <span>Production Date</span>
                  &nbsp;
                  <Tooltip
                    content="Records the date when the product was manufactured for inventory management."
                    placement="right"
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                </label>
                <Datetime
                  id="production_date"
                  inputProps={productionDateInputProps}
                  closeOnSelect
                  value={formValues.productionDate}
                  onChange={option => this.handleSelect('productionDate', option)}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </GridItem>
            </>
          ) : (
            <>
              <GridItem rowStart={4}>
                <label htmlFor="recurringDateStart">Recurring Start Date</label>
                <Datetime
                  id="recurringDateStart"
                  inputProps={recurringDateStartInputProps}
                  closeOnSelect
                  value={formValues.recurringDateStart}
                  onChange={option => this.handleSelect('recurringDateStart', option)}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </GridItem>
              <GridItem rowStart={4}>
                <label htmlFor="recurringDateEnd">Recurring End Date</label>
                <Datetime
                  id="recurringDateEnd"
                  inputProps={recurringDateEndInputProps}
                  closeOnSelect
                  value={formValues.recurringDateEnd}
                  onChange={option => this.handleSelect('recurringDateEnd', option)}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </GridItem>
              <GridItem rowStart={4}>
                <label htmlFor="delivery_date">Scheduled Delivery Date</label>
                <Datetime
                  id="delivery_date"
                  isValidDate={valid}
                  inputProps={deliveryDateInputProps}
                  closeOnSelect
                  value={formValues.deliveryDate}
                  className={this.props.error && formValues.deliveryDate === '' ? 'error' : ''}
                  onChange={option => this.handleSelect('deliveryDate', option)}
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </GridItem>
              <GridItem rowStart={4}>
                <label htmlFor="delivery_time">Delivery Time</label>
                <Datetime
                  id="delivery_time"
                  inputProps={deliveryTimeInputProps}
                  closeOnSelect
                  value={formValues.scheduledDeliveryTime}
                  onChange={option => this.handleSelect('scheduledDeliveryTime', option)}
                  timeConstraints={{ minutes: { step: 5 } }}
                  timeFormat="HH:mm"
                  dateFormat={false}
                />
              </GridItem>
              <GridItem rowStart={5} colSpan={2}>
                <Text as="h5" my="10px">
                  Delivery Days
                </Text>
                <SimpleGrid columns={7} spacing={5}>
                  <Box>
                    <Text>Mon</Text>
                    <input
                      type="text"
                      value={formValues.ordersPerDayOfWeek[0]}
                      onChange={option => this.handleSelect('ordersPerDayOfWeek', option, 0)}
                      className="form-control"
                    />
                  </Box>
                  <Box>
                    <Text>Tue</Text>
                    <input
                      type="text"
                      value={formValues.ordersPerDayOfWeek[1]}
                      onChange={option => this.handleSelect('ordersPerDayOfWeek', option, 1)}
                      className="form-control"
                    />
                  </Box>
                  <Box>
                    <Text>Wed</Text>
                    <input
                      type="text"
                      value={formValues.ordersPerDayOfWeek[2]}
                      onChange={option => this.handleSelect('ordersPerDayOfWeek', option, 2)}
                      className="form-control"
                    />
                  </Box>
                  <Box>
                    <Text>Thu</Text>
                    <input
                      type="text"
                      value={formValues.ordersPerDayOfWeek[3]}
                      onChange={option => this.handleSelect('ordersPerDayOfWeek', option, 3)}
                      className="form-control"
                    />
                  </Box>
                  <Box>
                    <Text>Fri</Text>
                    <input
                      type="text"
                      value={formValues.ordersPerDayOfWeek[4]}
                      onChange={option => this.handleSelect('ordersPerDayOfWeek', option, 4)}
                      className="form-control"
                    />
                  </Box>
                  <Box>
                    <Text>Sat</Text>
                    <input
                      type="text"
                      value={formValues.ordersPerDayOfWeek[5]}
                      onChange={option => this.handleSelect('ordersPerDayOfWeek', option, 5)}
                      className="form-control"
                    />
                  </Box>
                  <Box>
                    <Text>Sun</Text>
                    <input
                      type="text"
                      value={formValues.ordersPerDayOfWeek[6]}
                      onChange={option => this.handleSelect('ordersPerDayOfWeek', option, 6)}
                      className="form-control"
                    />
                  </Box>
                </SimpleGrid>
              </GridItem>
            </>
          )}
        </Grid>
        <Grid templateColumns="repeat(5, 1fr)" gap={10}>
          <GridItem colSpan={5}>
            <Flex alignItems="baseline">
              <Divider bg="black" flex={2} />
              <Text px={8} fontSize="15px" textTransform="uppercase">
                Purchase Order Lines
              </Text>
              <Divider bg="black" flex={2} />
              <Box mx={3}>
                {this.props.formValues.lines.length < 8 && (
                  <IconButton
                    data-test="po-form-add-po-line"
                    mt="10px"
                    colorScheme="actionSecondary"
                    isRound={true}
                    icon={<IoAdd />}
                    onClick={this.props.addPurchaseLine}
                  />
                )}
              </Box>
            </Flex>
          </GridItem>
          {this.props.formValues.lines.map((line, i) => {
            return (
              <GridItem colSpan={8}>
                <Grid templateColumns="repeat(8)" gap={2}>
                  {this.state.dateWarnings.indexOf(i) > -1 ? (
                    <GridItem>
                      <div
                        onClick={this.removeWarning.bind(this, i)}
                        style={{ textTransform: 'none' }}
                        className="fab-date-alert alert alert-info"
                      >
                        <i onClick={this.removeWarning.bind(this, i)} className="flaticon-circle" />
                        The date you have selected is greater than 14 days in the past. Please ensure you have selected
                        the correct date before continuing
                      </div>
                    </GridItem>
                  ) : (
                    <GridItem colStart={8} />
                  )}
                  <GridItem>
                    <Stack direction="row" h="100px">
                      <Text
                        as="h2"
                        alignSelf="center"
                        color="var(--chakra-colors-accent-one-default)"
                        fontSize="14px"
                        fontWeight={500}
                      >
                        {i + 1}
                      </Text>
                      <Divider orientation="vertical" bg="black" borderColor="black" h="70%" mx={5} />
                      <Box flex={1} pl={2} minW="200px">
                        <label htmlFor="select-input-product">
                          <span>Product</span>
                          &nbsp;
                          <Tooltip content="Select the specific product from the dropdown list." placement="right">
                            <IconButton
                              width="14px"
                              height="14px"
                              padding="0"
                              minW="auto"
                              borderRadius="50%"
                              color="#878787"
                              icon={<IoInformationCircleOutline size="14px" />}
                              variant="unstyled"
                            />
                          </Tooltip>
                        </label>
                        <SelectField
                          name="select-input-product"
                          value={inputProductOptions.find(option => option.value === line.inputProductUid) || null}
                          /* country is hard-coded for time being
                            plans to merge current form with InternationalOrderForm
                            which would render form values.
                          */
                          options={inputProductOptions}
                          className={this.props.error && line.inputProductUid === '' ? 'error' : ''}
                          placeholder="Select a product"
                          onChange={this.props.handleLineSelectValue.bind(this, i, 'inputProductUid')}
                        />
                      </Box>
                    </Stack>
                  </GridItem>
                  {this.props.singleOrderForm && (
                    <GridItem>
                      <Box minW="200px">
                        <label htmlFor="expected_production_date">
                          <span>Expected Production Date</span>
                          &nbsp;
                          <Tooltip
                            content="Indicates the anticipated date for product manufacturing."
                            placement="right"
                          >
                            <IconButton
                              width="14px"
                              height="14px"
                              padding="0"
                              minW="auto"
                              borderRadius="50%"
                              color="#878787"
                              icon={<IoInformationCircleOutline size="14px" />}
                              variant="unstyled"
                            />
                          </Tooltip>
                        </label>
                        <Datetime
                          id="expected_production_date"
                          inputProps={fabricationDateInputProps}
                          closeOnSelect
                          value={line.expectedProductionDate}
                          onChange={this.setFabricationDate.bind(this, i, 'expectedProductionDate')}
                          timeFormat={false}
                          dateFormat="YYYY-MM-DD"
                        />
                      </Box>
                    </GridItem>
                  )}
                  <GridItem>
                    <Box minW="50px">
                      <label htmlFor="buy_quantity">
                        <span>Quantity</span>
                        &nbsp;
                        <Tooltip content="Specifies the amount of the product ordered." placement="right">
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            icon={<IoInformationCircleOutline size="14px" />}
                            variant="unstyled"
                          />
                        </Tooltip>
                      </label>
                      <input
                        id="buy_quantity"
                        type="number"
                        className={`form-control ${this.props.error && line.numberOfBins === '' ? 'error' : ''}`}
                        placeholder="Quantity"
                        onChange={this.props.handleLineSelectValue.bind(this, i, 'buyQuantity')}
                        value={line.buyQuantity}
                        onWheel={e => e.target.blur()}
                        style={{ minHeight: '40px' }}
                      />
                    </Box>
                  </GridItem>
                  <GridItem>
                    <Box minW="80px">
                      <label htmlFor="price_per_unit">
                        <span>FOB Price</span>
                        &nbsp;
                        <Tooltip
                          content="Captures the Free on Board (FOB) price at the point of shipment."
                          placement="right"
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            icon={<IoInformationCircleOutline size="14px" />}
                            variant="unstyled"
                          />
                        </Tooltip>
                      </label>
                      <input
                        id="price_per_unit"
                        type="number"
                        className={`form-control ${this.props.error && line.pricePerUnit === '' ? 'error' : ''}`}
                        placeholder="FOB Price"
                        onChange={this.props.handleLineSelectValue.bind(this, i, 'pricePerUnit')}
                        value={line.pricePerUnit}
                        onWheel={e => e.target.blur()}
                        style={{ minHeight: '40px' }}
                      />
                    </Box>
                  </GridItem>
                  {/* <!-- CREATE PO-> GROUP HOUSED PREMIUM ONLY FOR PRODUCT TYPE PORK  --> */}
                  {productType === Constants.PRODUCT_TYPE_LIST.PORK && (
                    <GridItem>
                      <Box minW="170px">
                        <label htmlFor="group_house_premium">
                          <span>Group Housed Premium </span>
                          &nbsp;
                          <Tooltip
                            content="Indicates the Cost of Group Housed Premium for Group Housed product"
                            placement="right"
                          >
                            <IconButton
                              width="14px"
                              height="14px"
                              padding="0"
                              minW="auto"
                              borderRadius="50%"
                              color="#878787"
                              icon={<IoInformationCircleOutline size="14px" />}
                              variant="unstyled"
                            />
                          </Tooltip>
                        </label>
                        <input
                          id="group_housed_premium_rate"
                          type="number"
                          className={`form-control ${
                            this.props.error && line.groupHousedPremiumRate === '' ? 'error' : ''
                          }`}
                          placeholder="Group Housed Premium"
                          onChange={this.props.handleLineSelectValue.bind(this, i, 'groupHousedPremiumRate')}
                          value={line.groupHousedPremiumRate}
                          onWheel={e => e.target.blur()}
                          style={{ minHeight: '40px' }}
                        />
                      </Box>
                    </GridItem>
                  )}
                  
                  <GridItem>
                    <Box minW="140px">
                      <label htmlFor="order_type">
                        <span>Order Type</span>
                        &nbsp;
                        <Tooltip
                          content="Specifies the nature of the order, such as Spot or Contract."
                          placement="right"
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            icon={<IoInformationCircleOutline size="14px" />}
                            variant="unstyled"
                          />
                        </Tooltip>
                      </label>
                      <Flex alignItems="center">
                        <Box flex={1} mr={3}>
                          <SelectField
                            id="order_type"
                            name="select-input-product"
                            value={priceTypeOptions.find(option => option.value === line.priceType) || null}
                            options={priceTypeOptions}
                            onChange={this.props.handleLineSelectValue.bind(this, i, 'priceType')}
                          />
                        </Box>
                        {/* <Icon
                          w={6}
                          h={6}
                          cursor="pointer"
                          colorScheme="actionPrimary"
                          as={IoTrashOutline}
                          onClick={() => this.props.removePurchaseLine(i)}
                        /> */}
                      </Flex>
                    </Box>
                  </GridItem>
                  {isActiveOrderTypeNotes && (
                    <GridItem>
                      <Box minW="130px">
                        <label htmlFor="order_type_notes">
                          <span>Order Type Notes</span>
                        </label>

                        <input
                          id="order_type_notes"
                          type="text"
                          className={`form-control ${this.props.error && line.orderTypeNotes === '' ? 'error' : ''}`}
                          placeholder="Order Type Notes"
                          onChange={this.props.handleLineSelectValue.bind(this, i, 'orderTypeNotes')}
                          value={line.orderTypeNotes}
                          onWheel={e => e.target.blur()}
                          style={{ minHeight: '40px' }}
                        />
                      </Box>
                    </GridItem>
                  )}
                  <GridItem colStart={8} height="100%" display="flex" alignItems="center" justifyContent="center">
                    <Box minW="10px">
                      <Icon
                        w={6}
                        h={6}
                        cursor="pointer"
                        colorScheme="actionPrimary"
                        as={IoTrashOutline}
                        onClick={() => this.props.removePurchaseLine(i)}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={5}>
                    {line.priceType === 'formula' && (
                      <Grid templateColumns="repeat(5, 1fr)" gap={3}>
                        <GridItem pl={8}>
                          <label>Formula Name</label>
                          <select
                            className="form-control"
                            onChange={this.props.handleLineSelectValue.bind(this, i, 'formulaName')}
                            value={line.formulaName}
                          >
                            <option value="">(none selected)</option>
                            <option value="USDA National Weds 7d|usda,3,7">USDA National Weds 7d</option>
                            <option value="USDA National Fri 7d|usda,5,7">USDA National Fri 7d</option>
                            <option value="USDA National Weds 5d|usda,3,5">USDA National Weds 5d</option>
                            <option value="USDA National Fri 5d|usda,5,5">USDA National Fri 5d</option>
                          </select>
                        </GridItem>
                        <GridItem>
                          <label>Market</label>
                          <select
                            className="form-control"
                            onChange={this.props.handleLineSelectValue.bind(this, i, 'formulaMarket')}
                            value={line.formulaMarket}
                          >
                            <option value="USDA National">USDA National</option>
                          </select>
                        </GridItem>
                        <GridItem>
                          <label>Day</label>
                          <select
                            className="form-control"
                            value={line.formulaDay}
                            onChange={this.props.handleLineSelectValue.bind(this, i, 'formulaDay')}
                          >
                            <option value="">(none)</option>
                            <option value="1">Mon</option>
                            <option value="2">Tue</option>
                            <option value="3">Wed</option>
                            <option value="4">Thu</option>
                            <option value="5">Fri</option>
                            <option value="6">Sat</option>
                            <option value="0">Sun</option>
                          </select>
                        </GridItem>
                        <GridItem>
                          <label>Avg. Days</label>
                          <input
                            className="form-control"
                            onChange={this.props.handleLineSelectValue.bind(this, i, 'formulaDaysAveraged')}
                            type="number"
                            value={line.formulaDaysAveraged}
                            onWheelCapture={e => {
                              e.target.blur();
                            }}
                          />
                        </GridItem>
                        <GridItem>
                          <label>Basis</label>
                          <Flex alignItems="center">
                            <Text as="strong">$</Text>
                            <input
                              className="form-control"
                              onChange={this.props.handleLineSelectValue.bind(this, i, 'formulaBasis')}
                              type="number"
                              step=".01"
                              max="0"
                              value={line.formulaBasis}
                              onWheelCapture={e => {
                                e.target.blur();
                              }}
                            />
                          </Flex>
                        </GridItem>
                      </Grid>
                    )}
                    {line.priceType === 'contract' && (
                      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
                        <GridItem colStart={1} pl={8}>
                          <label htmlFor="contract_start_date">Contract Start Date</label>
                          <Datetime
                            id="contract_start_date"
                            inputProps={contractStartDateInputProps}
                            closeOnSelect
                            value={line.contractStartDate}
                            onChange={this.props.handleLineSelectValue.bind(this, i, 'contractStartDate')}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            isValidDate={current => validateSelectableContractStartDate(current, line.contractEndDate)}
                          />
                        </GridItem>
                        <GridItem colStart={2}>
                          <label htmlFor="contract_end_date">Contract End Date</label>
                          <Datetime
                            id="contract_end_date"
                            inputProps={contractEndDateInputProps}
                            closeOnSelect
                            value={line.contractEndDate}
                            onChange={this.props.handleLineSelectValue.bind(this, i, 'contractEndDate')}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            isValidDate={current => validateSelectableContractEndDate(current, line.contractStartDate)}
                          />
                        </GridItem>
                      </Grid>
                    )}
                  </GridItem>
                  <GridItem colSpan={5}>
                    <Box paddingX="10px">
                      {isActiveEstimatedProductionDetail && (
                        <ProductionDetails
                          {...{
                            unitsOfMeasure,
                            line,
                            lineIndex: i,
                            onChange: this.handleProductionDetailsChange,
                            isFrozenProduct: false,
                            isUSADomestic: true,
                            isChicago: true,
                            isInEditMode: true,
                            isInCreatePOMode: true,
                            onUpdateLine: this.handleUpdateLine,
                          }}
                        />
                      )}
                    </Box>
                  </GridItem>
                </Grid>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

export default SingleIngredientForm;
