import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Input,
  Select,
  Flex,
} from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import api from '../../../api';
import Constants from '../../../Constants';
import countryCodes from '../../../shared/countryCodes';
import CustomMultiSelect from '../../basic/CustomMultiSelect';

const UpdateFacilityInfo = ({ isOpenPopup, setIsOpenPopup, estId, editItem, updateList, addressData }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const updateInfo = async values => {
    const payload = {
      screen: 'facility_info',
      fields_info: [
        {
          ...(editItem?.id && { id: editItem?.id }),
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
          staff: +values.staff,
          daily_production: values.daily_production,
          daily_boining: values.daily_boining,
          boning_process: values.boning_process?.map(item => item.value),
          stunning_method: values.stunning_method,
          production_days: values.production_days?.map(item => item.value),
          category: values.category?.map(item => item.value),
          shift_times: values.shift_times?.map(item => item.value),
          raw_material: values.raw_material?.map(item => item.value),
          //   org_chart: orgChartLink.file_url,
          //   religious_certificates: rCertLinks.map(link => (link?.file_url ? link?.file_url : '')),
        },
      ],
    };

    try {
      setIsLoading(true);
      if (estId) {
        const response = await api.patch(`/op-establishment/${estId}`, payload);
        if (response.ok) {
          //   const json = await response.json();
          //   setData(prevState => prevState.map(item => (item.id === json.id ? json : item)));
          updateList(allList => {
            const updatedList = allList.map(item => {
              if (item.id === estId) {
                return { ...item, 
                          address: { 
                            line1: payload.fields_info[0]?.address1, 
                            line2: payload.fields_info[0]?.address2, 
                            city: payload.fields_info[0]?.city, 
                            state: payload.fields_info[0]?.state, 
                            country: payload.fields_info[0]?.country, 
                            post_code: payload.fields_info[0]?.zip 
                          }, 
                          facility_information: { ...item.facility_information, ...payload.fields_info[0] } 
                        };
              }
              return item;
            });
            return updatedList;
          });
          toast({
            title: 'Record updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
      setIsOpenPopup(false);
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      address1: addressData?.line1 || '',
      address2: addressData?.line2 || '',
      city: addressData?.city || '',
      state: addressData?.state || '',
      zip: addressData?.post_code || '',
      country: addressData?.country || '',
      daily_production: editItem?.daily_production || '',
      daily_boining: editItem?.daily_boining || '',
      boning_process: editItem?.boning_process || [],
      stunning_method: editItem?.stunning_method || '',
      production_days: editItem?.production_days || [],
      category: editItem?.category || [],
      shift_times: editItem?.shift_times || [],
      staff: editItem?.staff || '',
      raw_material: editItem?.raw_material || [],
    },
    onSubmit: updateInfo,
    validationSchema: Yup.object().shape({
      address1: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().required('Postal code is required'),
      country: Yup.string().required('Country is required'),
      daily_production: Yup.string(),
      daily_boining: Yup.string(),
      boning_process: Yup.array(),
      stunning_method: Yup.string(),
      production_days: Yup.array(),
      category: Yup.array(),
      shift_times: Yup.array(),
      staff: Yup.string(),
      raw_material: Yup.array(),
    }),
  });

  useEffect(() => {
    if (editItem) {
      setFieldValue('address1', editItem.address1);
      setFieldValue('address2', editItem.address2);
      setFieldValue('city', editItem.city);
      setFieldValue('state', editItem.state);
      setFieldValue('zip', editItem.zip);
      setFieldValue('country', editItem.country);
      setFieldValue('daily_production', editItem.daily_production);
      setFieldValue('daily_boining', editItem.daily_boining);
      setFieldValue(
        'boning_process',
        editItem.boning_process?.map(item => ({ value: item, label: item }))
      );
      setFieldValue('stunning_method', editItem.stunning_method);
      setFieldValue(
        'production_days',
        editItem.production_days?.map(item => ({ value: item, label: item }))
      );
      setFieldValue(
        'category',
        editItem.category?.map(item => ({ value: item, label: item }))
      );      
      setFieldValue(
        'shift_times',
        editItem.shift_times?.map(item => ({ value: item, label: item }))
      );
      setFieldValue('staff', editItem.staff);
      setFieldValue(
        'raw_material',
        editItem.raw_material?.map(item => ({ value: item, label: item }))
      );
    }
  }, [editItem]);

  return (
    <Modal isOpen={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW="min(870px, 80%)">
        <ModalHeader borderBottomWidth="1px" fontSize="20px">
          Update Facility Information
        </ModalHeader>
        <ModalCloseButton />
        <Formik>
          <form onSubmit={handleSubmit}>
            <ModalBody padding="22px">
              <Box flexGrow={1} marginBottom="20px">
                <FormLabel>Address*</FormLabel>
                <Input name="address1" value={values.address1} onChange={handleChange} />
                {errors.address1 && touched.address1 && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errors.address1}</AlertDescription>
                  </Alert>
                )}
              </Box>
              <Box flexGrow={1} marginBottom="20px">
                <FormLabel>Address line 2</FormLabel>
                <Input name="address2" value={values.address2} onChange={handleChange} />
                {errors.address2 && touched.address2 && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errors.address2}</AlertDescription>
                  </Alert>
                )}
              </Box>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <FormLabel>City*</FormLabel>
                  <Input name="city" value={values.city} onChange={handleChange} />
                  {errors.city && touched.city && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.city}</AlertDescription>
                    </Alert>
                  )}
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <FormLabel>State*</FormLabel>
                  <Input name="state" value={values.state} onChange={handleChange} />
                  {errors.state && touched.state && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.state}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <FormLabel>Postal Code*</FormLabel>
                  <Input name="zip" value={values.zip} onChange={handleChange} />
                  {errors.zip && touched.zip && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.zip}</AlertDescription>
                    </Alert>
                  )}
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <FormLabel>Country*</FormLabel>
                  <Select
                    name="country"
                    placeholder="Select"
                    height="40px"
                    onChange={e => {
                      setFieldValue('country', e.currentTarget.value);
                    }}
                    value={values.country}
                  >
                    {countryCodes.map(country => (
                      <option value={country.name} key={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                  {errors.country && touched.country && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.country}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <FormLabel>Daily Production</FormLabel>
                  <Input name="daily_production" value={values.daily_production} onChange={handleChange} />
                  {errors.daily_production && touched.daily_production && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.daily_production}</AlertDescription>
                    </Alert>
                  )}
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <FormLabel>Daily Boning</FormLabel>
                  <Input name="daily_boining" value={values.daily_boining} onChange={handleChange} />
                  {errors.daily_boining && touched.daily_boining && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.daily_boining}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
              <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="Category"
                    value={values.category}
                    options={Constants.category}
                    onChange={value => {
                      setFieldValue('category', value);
                    }}
                  />
                  {errors.category && touched.category && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.category}</AlertDescription>
                    </Alert>
                  )}
                </Box> 	                
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="What is your boning process"
                    value={values.boning_process}
                    options={Constants.boningProcesses}
                    onChange={value => {
                      setFieldValue('boning_process', value);
                    }}
                  />
                  {errors.boning_process && touched.boning_process && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.boning_process}</AlertDescription>
                    </Alert>
                  )}
                </Box>               
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
              <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <FormLabel>Method of Stunning</FormLabel>
                  <Input name="stunning_method" value={values.stunning_method} onChange={handleChange} />
                  {errors.stunning_method && touched.stunning_method && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.stunning_method}</AlertDescription>
                    </Alert>
                  )}
                </Box>                
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="Production Days"
                    value={values.production_days}
                    options={Constants.days}
                    onChange={value => {
                      setFieldValue('production_days', value);
                    }}
                  />
                  {errors.production_days && touched.production_days && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.production_days}</AlertDescription>
                    </Alert>
                  )}
                </Box>

              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
              <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="Number of shifts"
                    value={values.shift_times}
                    options={Constants.shifts}
                    onChange={value => {
                      setFieldValue('shift_times', value);
                    }}
                  />
                  {errors.shift_times && touched.shift_times && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.shift_times}</AlertDescription>
                    </Alert>
                  )}
                </Box>                
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <FormLabel>Number of Staff</FormLabel>
                  <Input name="staff" value={values.staff} onChange={handleChange} />
                  {errors.staff && touched.staff && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.staff}</AlertDescription>
                    </Alert>
                  )}
                </Box>

              </Flex>
              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="What raw materials does your facility supply"
                    value={values.raw_material}
                    options={Constants.rawMaterials}
                    onChange={value => {
                      setFieldValue('raw_material', value);
                    }}
                  />
                  {errors.raw_material && touched.raw_material && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errors.raw_material}</AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter borderTopWidth="1px" padding="16px 22px">
              <ButtonGroup marginLeft="auto">
                <Button type="button" fontSize="16px" width="126px" height="43px" onClick={() => setIsOpenPopup(false)}>
                  Close
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  fontSize="16px"
                  loadingText="Saving..."
                  isLoading={isLoading}
                >
                  Save
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};

UpdateFacilityInfo.propTypes = {
  isOpenPopup: PropTypes.bool,
  setIsOpenPopup: PropTypes.func,
  estId: PropTypes.number,
  editItem: PropTypes.shape({}) || null,
  updateList: PropTypes.func,
  addressData: PropTypes.shape({}) || null,
};
export default UpdateFacilityInfo;
