import { isEmpty } from 'lodash';
import moment from 'moment';
import Constants from './Constants';
import { convertToCamelCase } from './helpers';

/**
 * - getIngredientOptions(input_products, filterOptions) returns a list of ingredients
 *    based on the filters passed as arguments.
 *    By default filters out delisted
 * - filterOptions = [
 *                  {filter_key: "COUNTRY", filter_args: "[US]"},
 *                  {}, ..
 *                ]
 * - filterTypes is a dict with filter keys and functions
 */
function filterIngredientOptionsByCountry(ingredientsOptions, inputCountries) {
  if (!inputCountries) {
    return [];
  }
  const lowerCaseCountries = inputCountries.map(x => x.toLowerCase());
  const countryCodes = Constants.COUNTRY_CODINGS.filter(x => lowerCaseCountries.includes(x.name)).map(
    ({ code }) => code
  );
  if (!countryCodes) {
    return [];
  }
  const options = ingredientsOptions.filter(c => countryCodes.includes(c.country));
  return options;
}

const filterTypes = { COUNTRY: filterIngredientOptionsByCountry };

export function getIngredientOptions(inputProducts, filterOptions, skipDelisted = false) {
  let newInputProducts = inputProducts;
  if (filterOptions.length) {
    Object.entries(filterOptions).forEach(opt => {
      const key = opt[1].filter_key || opt[1].filterKey;
      const args = opt[1].filter_args || opt[1].filterArgs;
      newInputProducts = filterTypes[key].apply(null, [inputProducts, args]);
    });
  }

  return skipDelisted
    ? newInputProducts.map(el => ({ value: el.uid, label: el.name, delisted: el.delisted, cl: el.cl }))
    : newInputProducts
        .filter(product => !product.delisted)
        .map(el => ({ value: el.uid, label: el.name, delisted: el.delisted, cl: el.cl }));
}

/* -- getIngredientOptions end-- */

export function filterInputProducts(inputProducts, form) {
  let filteredInputProducts = inputProducts.filter(product => (form.isChilled ? product.fresh : !product.fresh));
  if (form.productType) {
    // filter by product type
    filteredInputProducts = filteredInputProducts.filter(product => product.productType === form.productType);
  }

  return filteredInputProducts;
}

/* -- today's date -- */

export function today() {
  return moment().format('YYYY-MM-DD');
}

// validates shipment dates
export function shipmentDateValidations(title, fieldName, inputDate, state) {
  // BOP-7950 - Shipment date - to be backdated
  // if (inputDate < today()) {
  //   return `${title} cannot be in the past.`;
  // }
  if (state) {
    switch (fieldName) {
      case 'shipmentDateStart':
        // start date > end date
        if (state.shipmentDateEnd && inputDate > state.shipmentDateEnd)
          return `${title} cannot be after Shipment End Date`;
        break;
      case 'shipmentDateEnd':
        // end date < start date
        if (state.shipmentDateStart && inputDate < state.shipmentDateStart)
          return `${title} cannot be before Shipment Start Date`;
        break;
      case 'arrivalDateStart':
        // start date > end date
        if (state.arrivalDateEnd && inputDate > state.arrivalDateEnd)
          return `${title} cannot be after Arrival End Date`;
        break;
      case 'arrivalDateEnd':
        // end date < start date
        if (state.arrivalDateStart && inputDate < state.arrivalDateStart)
          return `${title} cannot be before Arrival Start Date`;
        break;
      default:
        return null;
    }
  }
  return null;
}

// validation for expected production dates and production details (shipped_quantity, production dates)

export function productionDateValidations(title, fieldName, inputDate, state) {
  if (state) {
    switch (fieldName) {
      case 'shipment_date_end':
        // end date < any line's expected fabrication date
        if (inputDate && state.lines) {
          try {
            const errorLines = state.lines.find(line => inputDate < line.expected_production_date);
            if (errorLines) {
              return `${title} cannot be before Expected production date ${errorLines.expected_production_date} on line ${errorLines.idx}`;
            }
          } catch (e) {
            // TODO, log errors
            return null;
          }
        }
        break;
      default:
        return null;
    }
  }
  return null;
}

export function formatLinkToDisplay(url) {
  const urlArray = url.split('/');
  return urlArray[urlArray.length - 1];
}

// compare two arrays and igore the order
export function equalsIgnoreOrder(a, b) {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  // eslint-disable-next-line no-restricted-syntax
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
}

export function getLevelName(name) {
  if (typeof name === 'function') {
    return name().replace('null', '');
  }
  return name;
}

export function getInternalPoLabel(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'internal_po_number');
  if (obj) {
    return obj.valueStr;
  }
  return '';
}
export function getDefaultValueFromConfigs(arr, name) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === name);
  if (obj) {
    return obj.valueStr || obj.valueInt;
  }
  return '';
}

export function isManualPriceStrategyByEndUser(arr, endUserId) {
  const endUsers = convertToCamelCase(arr);
  const endUser = endUsers.find(u => u.id === endUserId);
  if (endUser) {
    if (endUser.pricingStrategy === Constants.PRICING_STRATEGY.MANUAL) {
      return true;
    }
  }
  return false;
}

export function getPricingStrategy(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'pricing_strategy');
  if (!obj) {
    return '';
  }
  return obj.valueStr;
}

export function isCombinePriceStrategy(arr) {
  return getPricingStrategy(arr) === Constants.PRICING_STRATEGY.COMBINE;
}

export function isManualPriceStrategy(arr) {
  return getPricingStrategy(arr) === Constants.PRICING_STRATEGY.MANUAL;
}

export function isManualPriceStrategyByConfigsAndEndUser(configs, endUsers, endUserId) {
  const pricingStrategy = getPricingStrategy(configs);
  if (pricingStrategy === Constants.PRICING_STRATEGY.DEFAULT) {
    return false;
  }
  if (pricingStrategy === Constants.PRICING_STRATEGY.MANUAL) {
    return true;
  }
  return isManualPriceStrategyByEndUser(endUsers, endUserId);
}

export function checkIfManualSurchargesApply(configsArray) {
  const configs = convertToCamelCase(configsArray);
  const manualSurchargeObj = configs.find(c => c.name === 'manual_fees');
  if (manualSurchargeObj) {
    return manualSurchargeObj.valueStr === 'on';
  }
  return false;
}

export function isDeliveryDateEditable(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'actual_delivery_date');
  if (obj) {
    return obj.valueInt === 1;
  }

  return false;
}
export function checkIfETDEditable(docs) {
  const obj = docs.find(doc => doc.docType === 'Grinder Copy Documents' && doc.id);

  return isEmpty(obj);
}

export function isPoHedgingEnabled(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'po_hedging');
  if (obj) {
    return obj.valueStr === 'on';
  }
  return false;
}

export function isInvoiceColdstoreValidationApplied(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'invoice_coldstore_validation_applied');
  if (obj) {
    return obj.valueStr === '1';
  }
  return false;
}

export function isQuantityReceivedEditable(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'quantity_received');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function showCourierTrackingNo(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'show_courier_tracking_no');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function showIsfFileDate(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'show_isf_file_date');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function showActualDateOfArrival(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'show_actual_date_of_arrival');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function showActualDateOfDeparture(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'show_actual_date_of_departure');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function showUsCustomsEntryNumber(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'show_us_customs_entry_number');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function showDocsSentToBank(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'show_docs_sent_to_bank');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function getDocsSentToCustomFieldName(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'docs_sent_to_customer_field_name');
  if (obj) {
    return obj.valueStr;
  }
  return 'Docs sent to customs broker';
}

export function getOptionsFromSettingsConfig(arr, name, subName, useKeyAsValueAndLabel = false) {
  let obj = arr.find(o => o.name === name)?.value;
  const options = [];

  if (obj) {
    if (subName) {
      obj = obj[subName] || {};
    }
    Object.keys(obj).forEach(k => {
      if (useKeyAsValueAndLabel) {
        options.push({ value: k, label: k });
      } else {
        options.push({ value: obj[k], label: obj[k] });
      }
    });
  }
  return options;
}

export function IsValidUSDomesticOrder(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'us_domestic_validation');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function isFinanceTableRowsValidationIgnored(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'finance_validation_ignored');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function showLiveClSection(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'show_live_cl');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export function getBuyActivityGroupByValueFromConfigs(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'buy_activity_query');
  if (obj) {
    return obj.valueStr;
  }
  return 'cl';
}

export function changeableKeyCreator(keys) {
  // creator
  let value = '';
  for (const key of keys) {
    value += JSON.stringify(key);
  }
  return value;
}

export function checkIfIsPackerUser(user) {
  const organisationFilter = user.organisation_filter || user.organisationFilter;
  if (organisationFilter) {
    return organisationFilter.entity === 'packer' || organisationFilter.entity === 'packer group';
  }
  return false;
}

export function checkIfIsGrinderUser(user) {
  const organisationFilter = user.organisation_filter || user.organisationFilter;
  if (organisationFilter) {
    return organisationFilter.entity === 'grinder' || organisationFilter.entity === 'grinder group';
  }
  return false;
}

export function checkIfIsAdminUser(user) {
  return !checkIfIsPackerUser(user) && !checkIfIsGrinderUser(user);
}
export function isDeliveryDateRequired(arr) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === 'required_delivery_date');
  if (obj) {
    return obj.valueInt === 1;
  }
  return false;
}

export const downloadFile = async (fileUrl, name = 'Document.pdf') => {
  try {
    const response = await fetch(fileUrl);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = decodeURI(name);
    link.dispatchEvent(new MouseEvent('click'));
  } catch (error) {
    console.error('Error downloading file', error);
  }
};

/**
 * Checks if a specific feature is enabled based on its configuration value.
 * 
 * This function looks up the feature name in the provided configuration array (usually retrieved from the Master Data Management system)
 * and checks if its corresponding value (`valueInt`) is set to 1, which indicates that the feature is enabled.
 * 
 * The purpose of this function is to enable or disable specific content or behavior on the application based on 
 * the configuration settings stored in the Master Data Management -> Config.
 * 
 * @param {Array} arr - The array of config objects, typically retrieved from the Master Data Management system->config.
 * @param {string} featureName - The name of the feature to check (e.g., 'show_us_customs_entry_number', 'show_docs_sent_to_bank').
 * @returns {boolean} - Returns `true` if the feature is enabled (i.e., `valueInt === 1`), otherwise returns `false`.
 */
export function isFeatureEnabled(arr, featureName) {
  const configs = convertToCamelCase(arr);
  const obj = configs.find(o => o.name === featureName);
  if (obj) {
    return obj.valueInt === 1;// Return true if feature is enabled
  }
  return false; // Return false if the feature is not found or is disabled
}
